import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";
import styles from "./timesheet.module.css";
import Loader from "../../Loader/Loader";
import { FaCheck, FaAngleDown, FaAngleRight } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { CiSearch } from "react-icons/ci";

const AdminTimeSheet = () => {
  const { userInfo } = useSelector((state) => state.auth || {});
  const { theme } = useSelector((state) => state.theme);
  const [timeSheetsData, setTimesheetsData] = useState([]);
  const [dataWithoutSearches, setDataWithoutSearches] = useState([]);
  const [otsData, setOTSData] = useState([]);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [expandedRow, setExpandedRow] = useState("");
  const [loading, setLooading] = useState(false);
  const [activeTab, setActiveTab] = useState("pending");
  const [searchTerm, setSearchTerm] = useState("");

  const getAllTimeSheet = async () => {
    setLooading(true);
    try {
      const res = await fetchApi.get("company-user-invoices/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setOTSData(res.data.results);
        setDataWithoutSearches(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLooading(false);
    }
  };
  let isFetched = useRef(false);
  useEffect(() => {
    if (isFetched.current) return;
    isFetched.current = true;
    getAllTimeSheet();
  }, []);
  const handlePageChange = async (url) => {
    setLooading(true);
    try {
      const res = await fetchApi.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setDataWithoutSearches(res.data.results);
        setOTSData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLooading(false);
    }
  };
  const updateTimeSheet = async () => {
    try {
      const res = await fetchApi.get("company-user-invoices/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setOTSData(res.data.results);
        setDataWithoutSearches(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };
  const handleApprove = async (id) => {
    try {
      const res = await fetchApi.patch(
        `company-user-invoices/${id}/`,
        { is_approved: true },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (res.status === 200) {
        toast.dismiss();
        toast.success("Approved Successfully");
        updateTimeSheet();
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };
  const handleDelete = async (id) => {
    try {
      const res = await fetchApi.delete(`company-user-invoices/${id}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      updateTimeSheet();
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };
  const handleSearch = async () => {
   if(!searchTerm){
    toast.dismiss();
    toast.error("Please enter a search term");
   }else{
    try {
      const res = await fetchApi.get(`company-user-invoices/?search=${searchTerm}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setOTSData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }else{
        toast.dismiss();
        toast.error(res.message)
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
   }
  };
  const toggleRow = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };
  const getTabStyles = (tabName) => {
    const isActive = activeTab === tabName;
    const activeColor =
      theme === "dark" ? "bg-red-600 text-white" : "bg-red-600 text-white";
    const inactiveColor =
      theme === "dark"
        ? "bg-[#2A2A2E] text-white"
        : "bg-gray-100 text-gray-600";

    return `w-1/2 text-center outline-none py-2 font-bold transition-colors duration-300 ${
      isActive ? activeColor : inactiveColor
    }`;
  };

  useEffect(() => {
    if (activeTab === "pending") {
      setTimesheetsData(otsData.filter((data) => data.is_approved === false));
    } else {
      setTimesheetsData(otsData.filter((data) => data.is_approved === true));
    }
  },[activeTab,otsData]);

  return (
    <div className=" h-full">
      {loading ? (
        <Loader style={{ height: "100%" }} />
      ) : (
        <>
          {" "}
          <div className="flex w-full justify-center items-center">
          <div
            className={`flex justify-between items-center mb-4 mt-4 max-w-screen-sm w-full rounded-3xl ${
              theme === "dark" ? "bg-gray-900" : "bg-gray-200"
            }`}
          >
            <button
              className={`${getTabStyles("pending")} rounded-l-3xl`}
              onClick={() => setActiveTab("pending")}
            >
              Pending
            </button>
            <button
              className={`${getTabStyles("approved")} rounded-r-3xl`}
              onClick={() => setActiveTab("approved")}
            >
              Approved
            </button>
          </div>
          </div>
          <div className="mt-2 mb-3">
            <div
              className={`py-2 px-2 flex gap-2 rounded-3xl md:w-1/2 bg-transparent outline-none border  ${
                theme === "dark"
                  ? "border-[#ff0000]"
                  : "border-gray-600 text-black"
              }`}
            >
              <input
                type="text"
                value={searchTerm}
                placeholder="Search here"
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  if (!e.target.value) {
                    setOTSData(dataWithoutSearches);
                    setNext(null);
                    setPrev(null);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                className="px-1 w-[87%] bg-transparent outline-none"
              />
              <CiSearch
                className="w-6 h-6 cursor-pointer"
                onClick={handleSearch}
              />
            </div>
          </div>
          <div className={`${styles.tableResponsive} max-w-screen-lg mt-8`}>
            <table
              className={`${theme === "dark" ? "text-white" : "text-black"} ${
                styles.table
              }`}
            >
              <thead>
                <tr>
                  <th>Title</th>
                  <th>Amount</th>
                  <th>OverTime Amount</th>
                  <th>Total Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {timeSheetsData.length > 0 ? (
                  timeSheetsData.map((item, i) => (
                    <>
                      <tr
                        key={i}
                        className={` ${
                          theme === "dark"
                            ? "hover:bg-gradient-to-r from-[#fff3] to-[#fff0]"
                            : "hover:bg-gradient-to-r from-[#6d6d6d33] to-[#fff0]"
                        }
                    ${
                      expandedRow === i
                        ? "border-b-0"
                        : "border-b border-red-500"
                    }
                    `}
                      >
                        <td
                          style={{ borderBottom: "none" }}
                          onClick={() => toggleRow(i)}
                          className="cursor-pointer flex gap-1 items-center"
                        >
                          {expandedRow === i ? (
                            <FaAngleDown />
                          ) : (
                            <FaAngleRight />
                          )}
                          {item.title}
                        </td>
                        <td style={{ borderBottom: "none" }}>{item.amount}</td>
                        <td style={{ borderBottom: "none" }}>
                          {item.overtime_amount}
                        </td>
                        <td style={{ borderBottom: "none" }}>
                          {item.total_amount}
                        </td>
                        <td style={{ borderBottom: "none" }}>
                          {item?.is_approved ? (
                            <span className="bg-green-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                              Approved
                            </span>
                          ) : (
                            <span className="bg-yellow-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                              Pending
                            </span>
                          )}
                        </td>
                        <td style={{ borderBottom: "none" }}>
                          {item.is_approved ? (
                            <Tooltip title="Delete" arrow placement="top">
                              <button
                                className="cursor-pointer"
                                onClick={() => handleDelete(item.id)}
                              >
                                {" "}
                                <MdOutlineDeleteOutline className="w-6 h-6" />
                              </button>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Approve" arrow placement="top">
                              <button
                                className="cursor-pointer "
                                onClick={() => handleApprove(item.id)}
                              >
                                <FaCheck className="w-5 h-5" />
                              </button>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                      {expandedRow === i && (
                        <tr>
                          <td
                            colSpan={6}
                            className={`p-4 ${
                              theme === "dark"
                                ? " text-white bg-gradient-to-r from-[#fff3] to-[#fff0]"
                                : " text-black bg-gradient-to-r from-[#6d6d6d33] to-[#fff0]"
                            }`}
                          >
                            <div className={` p-4 rounded`}>
                              <strong>Full Description:</strong>{" "}
                              {item.description}
                            </div>
                          </td>
                        </tr>
                      )}
                    </>
                  ))
                ) : (
                  <tr colSpan={6} className={`p-4`}>
                    No data found
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="w-full  mt-2 justify-between flex">
            <span>
              {prev && (
                <button
                  className="heroButton block"
                  onClick={() => handlePageChange(prev)}
                >
                  Previous
                </button>
              )}
            </span>
            <span>
              {next && (
                <button
                  className="heroButton block"
                  onClick={() => handlePageChange(next)}
                >
                  Next
                </button>
              )}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default AdminTimeSheet;

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    project: {},
  };

  const projectSlice = createSlice({
    name: 'projectDetails',
    initialState,
    reducers: {
      updateProject: (state, { payload }) => {
        state.project = payload?.project
      }
    },
  });
  export const { updateProject } = projectSlice.actions;

  export default projectSlice.reducer;
  
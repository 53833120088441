import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { fetchApi } from "../../utlis/axios";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import BackBtn from "../backBtn/BackBtn";
import ParticlesBackground from "../home/ParticalsBackground";


const StripePayment = () => {
  const [stripePromise, setStripePromise] = useState(
    loadStripe(
      "pk_test_51QEgYOLDqsyYoePCL7TQqYznuqrQVBwBZUVJSXo2uFU9Zn1y6g6qIK1aGbKTw0quFm7N2plIMIp5BSjyAoCPyzfr00D32vgtkr"
    )
  );
  const [clientSecret, setClientSecret] = useState(null);

  const getClientSecret = async () => {
    try {
      const result = await fetchApi.get("session/");
      if (result.status === 200) {
        setClientSecret(result?.data?.clientSecret);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getClientSecret();
  }, []);
  console.log(clientSecret)

  return (
    <>
    <ParticlesBackground id="particaljs" />
    <BackBtn bgColor={"red"} />
      {stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm clientSecret={clientSecret}/>
        </Elements>
      )}
    </>
  );
};

export default StripePayment;

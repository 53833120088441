import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";
import styles from "./timesheet.module.css";
import Loader from "../../Loader/Loader";
import { FaAngleDown, FaAngleRight } from "react-icons/fa";

const UserTimeSheet = () => {
  const { userInfo } = useSelector((state) => state.auth || {});
  const { theme } = useSelector((state) => state.theme);
  const [timeSheetsData, setTimesheetsData] = useState([]);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);
  const [expandedRow, setExpandedRow] = useState("");
  const [data, setData] = useState({});
  const [loading, setLooading] = useState(false);
  const [isTimeSheetAvailable, setTimeSheetAvailable] = useState(true);

  const getTimeSheet = async (data) => {
    try {
      const res = await fetchApi.get("user-timesheet/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        if (res.data.length > 0) {
          setTimeSheetAvailable(false);
          setData(res.data[res.data.length - 1]);
        } else {
          setTimeSheetAvailable(true)
        }
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  }
  const createTimeSheet = async () => {
   if(!data){
    return
   }else{
    try {
      const res = await fetchApi.post("user-timesheet/", data, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      updateTimeSheet()
      getTimeSheet()
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
   }
  };
  const getAllTimeSheet = async () => {
    setLooading(true);
    try {
      const res = await fetchApi.get("company-user-invoices/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setTimesheetsData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLooading(false);
    }
  };
  const updateTimeSheet = async () => {
    try {
      const res = await fetchApi.get("company-user-invoices/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setTimesheetsData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLooading(false);
    }
  };
let isFetched=useRef(false)
  useEffect(() => {
    if (isFetched.current) return
    isFetched.current=true
    getTimeSheet();
    getAllTimeSheet();
  }, []);
  const handlePageChange = async (url) => {
    setLooading(true)
    try {
      const res = await fetchApi.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setTimesheetsData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLooading(false);
    }
  };

  const toggleRow = (index) => {
    setExpandedRow((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className=" h-full w-full">
     <div className="flex justify-center">
     <div
        className={`${styles.main_Heading} ${
          theme === "dark" ? "text-white" : "text-black"
        } max-w-screen-lg text-left`}
      >
        TimeSheets
      </div>
     </div>
      <hr className="w-full" />
      <div className="flex flex-col my-2 items-center justify-center w-full lg:px-0 px-5">
       
          <div className="flex justify-start items-center max-w-screen-lg w-full">
            <button
            className="mb-5 heroButton w-[max-content]"
            onClick={() => createTimeSheet()}
            disabled={isTimeSheetAvailable}
          >
            Create Request
          </button>
          </div>
      </div>
      <div className="flex justify-center items-center flex-col lg:px-0 px-5">
        {loading ? (
          <Loader style={{ height: "100%" }} />
        ) : (
          timeSheetsData.length > 0 && (
            <>
              <div className={`${styles.tableResponsive} mt-6 max-w-screen-lg`} style={{overflowX:'auto', width:'100%'}}>
                <table
                  className={`${
                    theme === "dark" ? "text-white" : "text-black"
                  } ${styles.table}`}
                >
                  <thead>
                    <tr>
                      <th>Title</th>
                    <th>Amount</th>
                      
                      <th>OverTime Amount</th>
                      <th>Total Amount</th>
                      <th>Status</th>
                      <th>Paid</th>
                    </tr>
                  </thead>
                  <tbody>
                    {timeSheetsData.map((item, i) => (
                      <>
                        <tr
                          key={i}
                          className={` ${
                            theme === "dark"
                              ? "hover:bg-gradient-to-r from-[#fff3] to-[#fff0]"
                              : "hover:bg-gradient-to-r from-[#6d6d6d33] to-[#fff0]"
                          }
                    ${
                      expandedRow === i
                        ? "border-b-0"
                        : "border-b border-red-800"
                    }
                    `}
                        >
                          <td
                            style={{ borderBottom: "none" }}
                            onClick={() => toggleRow(i)}
                            className="flex gap-1 items-center cursor-pointer"
                          >
                            {expandedRow === i ? (
                              <FaAngleDown />
                            ) : (
                              <FaAngleRight />
                            )}
                            {item.title}
                          </td>
                          <td style={{ borderBottom: "none" }}>
                            {item?.amount}
                          </td>
                          <td style={{ borderBottom: "none" }}>
                            {item.overtime_amount}
                          </td>
                          <td style={{ borderBottom: "none" }}>
                            {item.total_amount}
                          </td>
                          <td style={{ borderBottom: "none" }}>
                            {item?.is_approved ? (
                              <span className="bg-green-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                                Approved
                              </span>
                            ) : (
                              <span className="bg-yellow-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                                Pending
                              </span>
                            )}
                          </td>
                          <td style={{ borderBottom: "none" }}>
                            {item.is_paid ? "Yes" : "No"}
                          </td>
                        </tr>
                        {expandedRow === i && (
                          <tr>
                            <td colSpan={6} className={`p-4 ${
                            theme === "dark"
                              ? "bg-gradient-to-r from-[#fff3] to-[#fff0]"
                              : "bg-gradient-to-r from-[#6d6d6d33] to-[#fff0]"
                          }`}>
                              <div
                                className={`${
                                  theme === "dark"
                                    ? " text-white"
                                    : " text-black"
                                } p-4 rounded `}
                              >
                                <strong>Full Description:</strong>{" "}
                                {item.description}
                              </div>
                            </td>
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="w-full  mt-2 justify-between flex">
                <span>
                  {prev && (
                    <button
                      className="heroButton block"
                      onClick={()=> handlePageChange(prev)}
                    >
                      Previous
                    </button>
                  )}
                </span>
                <span>
                  {next && (
                    <button className="heroButton block" onClick={()=> handlePageChange(next)}>
                      Next
                    </button>
                  )}
                </span>
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};

export default UserTimeSheet;

import { IoIosAdd } from "react-icons/io";
import { useDrop } from "react-dnd";
import { FcCancel } from "react-icons/fc";
import { FaPause, FaTasks,FaCheckCircle } from "react-icons/fa";
import Card from './Card'
const DropZone = ({ status, tickets, moveCard, theme,handleCreate }) => {
  const [, drop] = useDrop({
    accept: "CARD",
    drop: (item) => moveCard(item.id, status),
  });

  return (
    <div
      ref={drop}
      className={`parent-card group px-4 py-4 min-w-[350px] flex-shrink-0 min-h-[80vh] rounded-md flex flex-col ${
        theme === "dark" ? "bg-darkMode" : "bg-lightMode text-black"
      }`}
    >
      <div className="content">
        <div className="flex w-full justify-between mb-2">
          <div className="flex gap-1 items-center">{status === 'canceled' ?
            <FcCancel className="w-6 h-6 mr-2" /> : status ===  'paused' ? 
               <FaPause className="w-4 h-4 mr-2" />: status === 'in-progress' ?
                <FaTasks className="w-4 h-4 mr-2" /> : status === 'completed' ?
                <FaCheckCircle className="w-4 h-4 mr-2" />  : ''}
                <h2 className="font-semibold text-base capitalize">{status}</h2>
          </div>
          <div
            className={`${
              theme === "dark"
                ? "text-white hover:bg-dashboardDarkMode"
                : "hover:bg-slate-200"
            } p-2 rounded-md`}
          >
            <IoIosAdd className="w-5 h-5" />
          </div>
        </div>
        {tickets.map((ticket) => (
          <Card key={ticket.id} ticket={ticket} />
        ))}
      </div>
      <div className="mt-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
      <button
              className={`w-full flex items-center p-2 rounded-md justify-center border ${
                theme === "dark"
                  ? "border-red-500 hover:bg-dashboardDarkMode"
                  : "border-gray-600 text-black hover:bg-white"
              }`}
              onClick={() => handleCreate(status)}
            >
              <IoIosAdd className="w-6 h-6" />
            </button>
      </div>
    </div>
  );
};

export default DropZone
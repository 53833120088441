import React, { useEffect, useRef, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./user.module.css";
import profile from "../../../assets/face1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { CiEdit } from "react-icons/ci";
import HourlyRate from "../Settings/hourlyRate/HourlyRate";
import { updateFilterUsers } from "../../../Redux/Slices/users-slice";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Tooltip } from "@mui/material";

const User = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const users = useSelector((state) => state.allUsers?.filterUsers);
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  //const [users, setUsers] = useState([]);
  const [role, setRole] = useState("Time Reporter");
  const [roleId, setRoleId] = useState();
  const [projectList, setProjectList] = useState([]);
 {/* const [showHourRate, setShowHourRate] = useState();
  const [rateEditData, setRateEditData] = useState({
    payment_type: "",
    payment_amount: "",
    currency: "",
  });*/}
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    currency: "",
    payment_amount: "",
    payment_type: "",
    project: "",
    domain: userInfo?.user?.domain,
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [openDropdown, setOpenDropdown] = useState(null);
  const [modalForm, setModalForm] = useState("create");
  const [userId, setUserId] = useState("");
  const dispatch = useDispatch();

  const userLimits = {
    basic: 2,
    standard: 10,
    platinum: 50,
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      calculatePasswordStrength(value);
    }
  };
  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    setPasswordStrength(strength);
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    const currentUserCount = users.length;
    const userPlanName = userInfo?.user?.plan?.name?.toLowerCase();

    const allowedUsers = userLimits[userPlanName];

    if (allowedUsers && currentUserCount >= allowedUsers) {
      toast?.dismiss();
      toast?.warn(
        `You can only add up to ${allowedUsers} users with your current plan. To add more, please upgrade your subscription.`
      );
      return;
    }

    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.email ||
      !formData.password ||
      !formData.payment_amount ||
      !formData.currency ||
      !formData.payment_type ||
      !formData.project
    ) {
      toast?.dismiss();
      toast?.error("All fields are required!");
      return;
    }

    setAddLoading(true);
    const userData = new FormData();
    userData.append("first_name", formData.first_name);
    userData.append("last_name", formData.last_name);
    userData.append("email", formData.email);
    userData.append("password", formData.password);
    userData.append("payment_amount", formData.payment_amount);
    userData.append("currency", formData.currency);
    userData.append("payment_type", formData.payment_type);
    userData.append("project", formData.project);
    userData.append("role", roleId);
    userData.append("domain", userInfo.user.domain);

    try {
      const result = await fetchApi.post("add-user/", userData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (result.status === 201) {
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          payment_amount: "",
          payment_type: "",
          currency: "",
          project: "",
        });
        toast?.dismiss();
        toast?.success("User added successfully!");
        setIsModalOpen(false);
        setAddLoading(false);
        getUsers();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.email?.[0] ||
        error?.response?.data?.[0] ||
        "User add failed";
      toast?.dismiss();
      setAddLoading(false);
      toast?.error(errorMessage);
      console.log(error);
    } finally {
      setAddLoading(false);
    }
  };
  let isRoleFetched = false;
  const handleGetRoles = async () => {
    if (isRoleFetched) return;
    isRoleFetched = true;
    try {
      const result = await fetchApi.get("roles/");
      if (result.status === 200) {
        const data = result.data;
        const userRole = data?.results?.filter((item) => item.name === role);
        setRoleId(userRole[0]?.id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast?.dismiss();
        toast?.error("Your session is expired");
        return;
      }
      toast?.dismiss();
      toast?.error("Something went wrong");
      console.log("Error getting roles: ", error);
    }
  };
  let isProjectFetched = useRef(false);
  const getProjects = async () => {
    if (isProjectFetched.current) return;
    isProjectFetched.current = true;
    try {
      const result = await fetchApi.get("projects/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (result.status === 200) {
        const data = result.data;
        setProjectList(data.results);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast?.dismiss();
        toast?.error("Your session is expired");
        return;
      }
      toast?.dismiss();
      toast?.error("Something went wrong");
      console.log("Error getting projects: ", error);
    }
  };
  const getUsers = async () => {
    try {
      const response = await fetchApi.get("users/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        const filterUsers = response?.data?.results?.filter(
          (item) => item?.domain === userInfo?.user?.domain
        );
        dispatch(updateFilterUsers(filterUsers));
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast?.dismiss();
        toast?.error("Your session is expired");
        return;
      }
      console.log(error);

      setLoading(false);
      toast?.dismiss();
      toast?.error("Something went wrong");
    }
  };

  useEffect(() => {
    // console.log(userInfo)
    handleGetRoles();
    getProjects();
    //getUsers();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  const currencyList = [
    { name: "United States Dollar", code: "USD", symbol: "$" },
    { name: "Euro", code: "EUR", symbol: "€" },
    { name: "British Pound", code: "GBP", symbol: "£" },
    { name: "Indian Rupee", code: "INR", symbol: "₹" },
    { name: "Japanese Yen", code: "JPY", symbol: "¥" },
    { name: "Australian Dollar", code: "AUD", symbol: "A$" },
    { name: "Canadian Dollar", code: "CAD", symbol: "C$" },
    { name: "Swiss Franc", code: "CHF", symbol: "CHF" },
    { name: "Chinese Yuan", code: "CNY", symbol: "¥" },
    { name: "Swedish Krona", code: "SEK", symbol: "kr" },
    { name: "New Zealand Dollar", code: "NZD", symbol: "NZ$" },
    { name: "South African Rand", code: "ZAR", symbol: "R" },
    { name: "Singapore Dollar", code: "SGD", symbol: "S$" },
    { name: "Hong Kong Dollar", code: "HKD", symbol: "HK$" },
    { name: "Norwegian Krone", code: "NOK", symbol: "kr" },
    { name: "South Korean Won", code: "KRW", symbol: "₩" },
    { name: "Turkish Lira", code: "TRY", symbol: "₺" },
    { name: "Russian Ruble", code: "RUB", symbol: "₽" },
    { name: "Brazilian Real", code: "BRL", symbol: "R$" },
    { name: "Mexican Peso", code: "MXN", symbol: "$" },
    { name: "Malaysian Ringgit", code: "MYR", symbol: "RM" },
    { name: "Indonesian Rupiah", code: "IDR", symbol: "Rp" },
    { name: "Thai Baht", code: "THB", symbol: "฿" },
    { name: "United Arab Emirates Dirham", code: "AED", symbol: "د.إ" },
    { name: "Saudi Riyal", code: "SAR", symbol: "﷼" },
    { name: "Pakistani Rupee", code: "PKR", symbol: "RS" },
    { name: "Egyptian Pound", code: "EGP", symbol: "£" },
    { name: "Kenyan Shilling", code: "KES", symbol: "KSh" },
    { name: "Nigerian Naira", code: "NGN", symbol: "₦" },
    { name: "Argentine Peso", code: "ARS", symbol: "$" },
    { name: "Chilean Peso", code: "CLP", symbol: "$" },
    { name: "Czech Koruna", code: "CZK", symbol: "Kč" },
    { name: "Polish Zloty", code: "PLN", symbol: "zł" },
    { name: "Hungarian Forint", code: "HUF", symbol: "Ft" },
    { name: "Israeli Shekel", code: "ILS", symbol: "₪" },
    { name: "Danish Krone", code: "DKK", symbol: "kr" },
    { name: "Philippine Peso", code: "PHP", symbol: "₱" },
    { name: "Vietnamese Dong", code: "VND", symbol: "₫" },
    { name: "Bangladeshi Taka", code: "BDT", symbol: "৳" },
  ];

  const paymentTypeOptions = ["monthly", "hourly", "weekly"];

  const handleOptionChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
    setOpenDropdown(null);
  };
  const updateFormDataFromUser = (user) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      first_name: user.first_name || "",
      last_name: user.last_name || "",
      email: user.email || "",
      currency: user.currency || "",
      payment_amount: user.payment_amount?.toString() || "",
      payment_type: user.payment_type || "",
      project: user.project?.id || "",
      domain: user.domain || prevFormData.domain, // Preserve the existing domain if not present in the user object
    }));
    setUserId(user.id);
    setIsModalOpen(true);
    setModalForm("update");
  };
  const handleUpdateUser = async (e) => {
    e.preventDefault();

    // Check for required fields
    if (!formData.first_name || !formData.last_name || !formData.email) {
      toast?.dismiss();
      toast?.error("All fields are required!");
      return;
    }

    // Find the corresponding user from the users array
    const existingUser = users.find((user) => user.id === userId);

    if (!existingUser) {
      toast?.dismiss();
      toast?.error("User not found!");
      return;
    }

    // Compare formData with the existingUser to find changed fields
    const updatedFields = {};
    Object.keys(formData).forEach((key) => {
      if (
        key !== "password" && // Exclude password from comparison
        formData[key] !== existingUser[key] // Compare with the existing user
      ) {
        updatedFields[key] = formData[key];
      }
    });

    // If no fields were changed, abort the request
    if (Object.keys(updatedFields).length === 0) {
      toast?.dismiss();
      toast?.info("No changes were made.");
      return;
    }

    // Add `is_archived` explicitly, if necessary
    updatedFields.is_archived = false;

    setAddLoading(true);

    try {
      // Make a PATCH request with only the updated fields
      const result = await fetchApi.patch(`users/${userId}/`, updatedFields, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (result.status === 200 || result.status === 204) {
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          payment_amount: "",
          payment_type: "",
          currency: "",
          project: "",
        });
        toast?.dismiss();
        toast?.success("User updated successfully!");
        setIsModalOpen(false);
        setAddLoading(false);
        getUsers();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast?.dismiss();
        toast?.error("Your session is expired");
        return;
      }
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.email?.[0] ||
        error?.response?.data?.[0] ||
        "User update failed";
      toast?.dismiss();
      setAddLoading(false);
      toast?.error(errorMessage);
      console.log(error);
    } finally {
      setAddLoading(false);
    }
  };
  const handleDelete = async (id) => {
    try {
      // Make a PATCH request with only the updated fields
      const result = await fetchApi.delete(`users/${id}/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      toast?.dismiss();
      toast?.success("User deleted successfully!");
      getUsers();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast?.dismiss();
        toast?.error("Your session is expired");
        return;
      }
      toast?.dismiss();
      toast?.error(error.message);
      console.log(error);
    }
  };

  return (
    <>
      <ToastContainer />
      <style>
        {`
      .input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
   border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"};
  box-sizing: border-box;
  background-color: transparent;
  option{
     color: #000000; 
    }
}
      `}
      </style>
      {/*showHourRate && (
        <div className={styles.modalOverlay}>
          <div
            className={`${styles.modal} ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#e5e5e5] text-black"
            }`}
          >
            <HourlyRate setShowHourRate={setShowHourRate} data={rateEditData} />
          </div>{" "}
        </div>
      )*/}
      <div
        className={`${styles.main_Heading} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        Users
      </div>
      <hr className="w-full" />
      <div
        className={`${styles.outlet}  ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div
              className={`${styles.modal} ${
                theme === "dark"
                  ? "bg-[#212529] text-white"
                  : "bg-[#e5e5e5] text-black"
              }`}
              style={{ width: "max-content" }}
            >
              <div className={styles.modalHeader}>
                <h2 className="font-semibold ">
                  {modalForm === "update" ? "Update User" : "Add User"}
                </h2>
                <button
                  className={styles.closeButton}
                  onClick={() => {
                    setIsModalOpen(false);
                    setModalForm("craete");
                    setFormData({
                      first_name: "",
                      last_name: "",
                      email: "",
                      password: "",
                      payment_amount: "",
                      payment_type: "",
                      currency: "",
                      project: "",
                    });
                  }}
                >
                  &times;
                </button>
              </div>
              <form
                onSubmit={
                  modalForm === "update" ? handleUpdateUser : handleAddUser
                }
              >
                <div className="grid sm:grid-cols-2 grid-cols-1 sm:gap-4">
                  <div className={styles.formGroup}>
                    <label>First name</label>
                    <input
                      className="input"
                      type="text"
                      name="first_name"
                      value={formData.first_name}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className={styles.formGroup}>
                    <label>Last name</label>
                    <input
                      className="input"
                      type="text"
                      name="last_name"
                      value={formData.last_name}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className={styles.formGroup}>
                    <label>E-Mail</label>
                    <input
                      className="input"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div
                    className={styles.formGroup}
                    style={{ marginBottom: "0px" }}
                  >
                    <label>Password</label>
                    <div className="relative">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        placeholder="Enter your password"
                        className="input"
                        style={{ paddingRight: "35px" }}
                      />
                      <div
                        className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {showPassword ? <FaEye /> : <FaEyeSlash />}
                      </div>
                    </div>
                    <div className="w-full h-2 mt-2 rounded-full overflow-hidden">
                      <div
                        className={`h-full rounded-full transition-all duration-300 ${
                          passwordStrength < 2
                            ? "bg-red-500"
                            : passwordStrength < 4
                            ? "bg-orange-500"
                            : "bg-green-500"
                        }`}
                        style={{ width: `${(passwordStrength / 5) * 100}%` }}
                      ></div>
                    </div>
                  </div>
                  <div className="formGroup mb-[15px]">
                    <label>Currency</label>
                    <div className="relative">
                      <div
                        className={`cursor-pointer p-2 border rounded w-full ${
                          theme === "dark"
                            ? "bg-transparent text-white border-[#ffffff]"
                            : "bg-transparent text-black border-black"
                        }
                      ${formData.currency ? "" : "text-[#ABADB3]"}
                      `}
                        onClick={() =>
                          setOpenDropdown((prev) =>
                            prev === "currency" ? null : "currency"
                          )
                        }
                      >
                        {formData.currency
                          ? currencyList.find(
                              (c) => c.code === formData.currency
                            )?.name
                          : "Select Currency"}
                      </div>
                      {openDropdown === "currency" && (
                        <div
                          className={`absolute z-10 mt-2 max-h-[200px] overflow-y-auto w-full rounded shadow-lg ${
                            theme === "dark"
                              ? "bg-dashboardDarkMode"
                              : "bg-white"
                          }`}
                        >
                          {currencyList.map((currency, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                handleOptionChange("currency", currency.code)
                              }
                              className={`p-2 cursor-pointer ${
                                theme === "dark"
                                  ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                  : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                              }`}
                            >
                              {currency.name}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  {/* Payment Type Selector */}
                  <div className="formGroup mb-[15px]">
                    <label>Payment Type</label>
                    <div className="relative">
                      <div
                        className={`cursor-pointer p-2 border rounded w-full ${
                          theme === "dark"
                            ? "bg-transparent text-white border-[#ffffff] "
                            : "bg-transparent text-black border-black"
                        }
                      ${formData.payment_type ? "" : "text-[#ABADB3]"}
                      `}
                        onClick={() =>
                          setOpenDropdown((prev) =>
                            prev === "paymentType" ? null : "paymentType"
                          )
                        }
                      >
                        {formData.payment_type || "Select Payment Type"}
                      </div>
                      {openDropdown === "paymentType" && (
                        <div
                          className={`absolute z-10 mt-2 max-h-[200px] overflow-y-auto w-full rounded shadow-lg ${
                            theme === "dark"
                              ? "bg-dashboardDarkMode"
                              : "bg-white"
                          }`}
                        >
                          {paymentTypeOptions.map((type, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                handleOptionChange("payment_type", type)
                              }
                              className={`p-2 cursor-pointer ${
                                theme === "dark"
                                  ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                  : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                              }`}
                            >
                              {type}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <label>Payment Amount</label>
                    <input
                      className="input placeholder:text-[#ABADB3]"
                      type="number"
                      name="payment_amount"
                      value={formData.payment_amount}
                      onChange={handleInputChange}
                      placeholder="Enter an amount"
                    />
                  </div>

                  <div className="formGroup mb-[15px]">
                    <label>Project</label>
                    <div className="relative">
                      <div
                        className={`cursor-pointer p-2 border rounded w-full ${
                          theme === "dark"
                            ? "bg-transparent text-white border-[#ffffff]"
                            : "bg-transparent text-black border-black"
                        } ${formData.project ? "" : "text-[#ABADB3]"}`}
                        onClick={() =>
                          setOpenDropdown((prev) =>
                            prev === "project" ? null : "project"
                          )
                        }
                      >
                        {formData.project
                          ? projectList.find((c) => c.id === formData.project)
                              ?.title || "Choose project"
                          : "Choose project"}
                      </div>
                      {openDropdown === "project" && (
                        <div
                          className={`absolute z-10 mt-2 max-h-[200px] overflow-y-auto w-full rounded shadow-lg ${
                            theme === "dark"
                              ? "bg-dashboardDarkMode"
                              : "bg-white"
                          }`}
                        >
                          {projectList.map((project, index) => (
                            <div
                              key={index}
                              onClick={() =>
                                handleOptionChange("project", project.id)
                              }
                              className={`p-2 cursor-pointer ${
                                theme === "dark"
                                  ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                                  : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                              }`}
                            >
                              {project.title}
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className={styles.formGroup}>
                    <label>User role</label>
                    <select
                      className="input"
                      name="role"
                      value={role}
                      onChange={(e) => setRole(e.target.value)}
                    >
                      <option value="Time Reporter">Time reporter</option>
                      <option value="Admin">Admin</option>
                    </select>
                  </div>
                </div>

                <div className={styles.actions} disabled={loading}>
                  <button type="submit" className="heroButton w-full">
                    {addLoading
                      ? "Loading..."
                      : modalForm === "update"
                      ? "Update"
                      : "Create"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {loading ? (
          <Loader />
        ) : (
          <>
            <button
              className="mb-5 heroButton"
              onClick={() => setIsModalOpen(true)}
            >
              + New User
            </button>
            <div className="overflow-x-auto">
              <div className={styles.tableResponsive}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Empolyee</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Joining Date</th>
                      <th>Role</th>
                      <th>Hourly Rate</th>
                      <th className="flex justify-center items-center">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users?.map((data, i) => (
                      <tr
                        key={data?.id}
                        className={`${
                          theme === "dark"
                            ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                            : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                        }`}
                      >
                        <td className="py-1">
                          <img
                            src={profile}
                            alt="user icon"
                            className={styles.img}
                          />
                        </td>
                        <td
                        >
                          {data?.first_name} {data?.last_name}
                        </td>
                        <td>{data?.email}</td>
                        <td>{formatDate(data?.created_at)}</td>
                        <td>
                          {typeof data?.role === "object"
                            ? data?.role?.name
                            : data?.role}
                        </td>
                        <td>
                          <span className="flex gap-2 items-center">
                            {data?.payment_amount} {data?.currency} /{" "}
                            {data?.payment_type === "hourly"
                              ? "Hour"
                              : data?.payment_type === "monthly"
                              ? "Month"
                              : " Week"}
                          </span>
                        </td>
                        <td>
                          {" "}
                          {userInfo?.user?.role?.name === "Admin" ? (
                            <div className="flex items-center gap-2">
                              <Tooltip title="Edit" arrow placement="top">
                                <button
                                  onClick={() => updateFormDataFromUser(data)}
                                >
                                  <CiEdit
                                    className={`w-5 h-5 cursor-${
                                      userInfo?.user?.role?.name === "Admin"
                                        ? "pointer"
                                        : "not-allowed"
                                    }`}
                                  />
                                </button>
                              </Tooltip>
                              <Tooltip title="Delete" arrow placement="top">
                                <button onClick={() => handleDelete(data.id)}>
                                  <MdOutlineDeleteOutline className="w-5 h-5 cursor-pointer" />
                                </button>
                              </Tooltip>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default User;

import React, { useEffect} from "react";
import { Route, Routes, BrowserRouter, Navigate } from "react-router-dom";
import HomePage from "./components/home/HomePage";
import Login from "./components/authpages/login/Login";
import SignUp from "./components/authpages/signup/SignUp";
import RegCompany from "./components/authpages/registerCompany/RegCmpany"
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import SubscriptionPage from "./components//subcriptions/SubscriptionPage";
import Dashboard from "./components/Dashboard/Dashboard";
import Summary from "./components/Dashboard/summary/Summary";
import Activity from "./components/Dashboard/activity/Activity";
import Users from "./components/Dashboard/users/Users";
import AnalyticsAndCharts from "./components/Dashboard/analyticsAndCharts/AnalyticsAndCharts";
import Reports from "./components/Dashboard/reports/Reports";
import PersonalInformation from "./components/Dashboard/Settings/personalInformation/PersonalInformation";
import Error from "./components/404error/Error";
import TermsOfService from "./components/terms/TermsOfService";
import PrivacyPolicy from "./components/privacyPolicy/PrivacyPolicy";
import { useSelector } from "react-redux";
import StripePayment from "./components/stripePayment/StripePayment";
import AllFeatures from './components/subcriptions/AllFeatures'
import Invoices from "./components/stripePayment/Invoices";
import UpdateCompany from "./components/Dashboard/company/UpdateCompany";
import CreateOvertime from "./components/Dashboard/overtime/CreateOvertime";
import OfftimeCreate from "./components/Dashboard/offtime/OfftimeCreate";
import UserTimeSheet from "./components/Dashboard/timesheet/UserTimeSheet";
import Board from "./components/Dashboard/board/Board";
import ProjectDetail from './components/Dashboard/board/ProjectDetail'

const App = () => {
  const { isLogin } = useSelector((state) => state?.auth || "");
  const { theme } = useSelector((state) => state.theme);

  useEffect(() => {
    document.body.className = theme === "dark" ? "dark-theme" : "light-theme";
  }, [theme]);

  
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} exact />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/registercompany" element={<RegCompany />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/subscriptionplan" element={<SubscriptionPage parent='subcriptionplan'/>} />
        <Route path="/terms_of_services" element={<TermsOfService />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/see-all-features" element={ <AllFeatures parent="home"/> } />
        <Route path="/see-features" element={ <AllFeatures /> } />
        <Route
          path="/dashboard"
          element={
            isLogin ? <Dashboard /> : <Navigate to='/login'/> }
        >

          <Route index element={<Navigate to="/dashboard/summary" />} />
          <Route path="summary" element={<Summary />} />
          <Route path="activity" element={<Activity />} />
          <Route path="users" element={<Users />} />
          <Route path="analytics_charts" element={<AnalyticsAndCharts />} />
          <Route path="reports" element={<Reports />} />
          <Route path="changeplan" element={<SubscriptionPage parant='no'/>}/>
          <Route path='invoices' element={<Invoices/>}/>
          <Route path="updatecompany" element={<UpdateCompany/>}/>
          <Route path="overtime" element={<CreateOvertime/>}/>
          <Route path="timeoffs" element={<OfftimeCreate/>}/>
          <Route path="timesheet" element={<UserTimeSheet/>}/>
          <Route path='board' element={<Board/>}/>
          <Route path='project/:id' element={<ProjectDetail/>}/>
          <Route path="setting/personalinformation" element={<PersonalInformation />} />
        </Route>
        <Route path="/payment" element={<StripePayment />} />

        <Route path="*" element={<Error />} />

      </Routes>
    </BrowserRouter >
  );
};

export default App;

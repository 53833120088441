import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./Navbar/DashboardNavbar";
import GoToTop from "../goToTop/GoToTop";
import { useSelector } from "react-redux";
import AlertBar from "./AlertBar";
import SideBar from "./side_bar/Side_Bar";
import { MdOutlineChat } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import { toggleAuth } from "../../Redux/Slices/auth-slice";
import { toggleDataLoading,updateFilterUsers,updateUserId,updateUsers } from "../../Redux/Slices/users-slice";
import { useDispatch } from "react-redux";
import { fetchApi } from "../../utlis/axios";
import { toast } from "react-toastify";

const Dashboard = () => {
  const navigate=useNavigate()
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { theme } = useSelector((state) => state.theme);
  const  {userId,dataLoading}=useSelector((state) => state?.allUsers || {});
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const getSubdomain = () => {
    const host = window.location.host
    if (host.includes("localhost")) {
      return host.split(".localhost")[0];
    }else if(host.includes(".bugtrackin")){
      return host.split(".bugtrackin.com")[0];
    }
    return null;
  };

  const subdomain = getSubdomain();
  useEffect(()=>{
    if(subdomain === userInfo?.user?.domain){
      return
    }else{
      navigate('/')
    }
  })

 
 {/* const getUsers = async () => {
    dispatch(toggleDataLoading(true))
    console.log('i am true')
    try {
      const response = await fetchApi.get("users/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        if (userInfo?.user?.role?.name === "Time Reporter") {
          dispatch(updateUserId(userInfo?.user?.id))
          dispatch(toggleDataLoading(false))
          console('i am fasle after 200')
        } else {
          const result = response?.data?.results?.filter(
            (item) =>
              item.role.name === "Time Reporter" &&
              item.domain === userInfo.user.domain
          );
          if (result.length === 0) {
            dispatch(toggleDataLoading(false))
            dispatch(updateUserId(userInfo?.user?.id))
            console.log('i am fasle result lebgth is 0')
          } else {
            dispatch(toggleDataLoading(false))
          dispatch(updateUserId(result?.[0]?.id))
          console.log('i am fasle else')
          }
          dispatch(updateUsers(result))
          dispatch(toggleDataLoading(false))
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      console.log(error);
      dispatch(toggleDataLoading(false))
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };
*/}
let isFetched = false;
const getUsers = async () => {
  if (isFetched) return; // Skip the API call if already fetched
  isFetched = true;
  dispatch(toggleDataLoading(true));
  try {
    const response = await fetchApi.get("users/", {
      headers: {
        Authorization: `Bearer ${userInfo.access_token}`,
      },
    });

    if (response.status === 200) {
      let result = [];
      const filterUsers = response?.data?.results?.filter(
        (item) => item?.domain === userInfo?.user?.domain
      );
      dispatch(updateFilterUsers(filterUsers))
      if (userInfo?.user?.role?.name === "Time Reporter") {
        dispatch(updateUserId(userInfo?.user?.id));
      } else {
        result = response?.data?.results?.filter(
          (item) =>
            item.role.name === "Time Reporter" &&
            item.domain === userInfo.user.domain
        );
        if (result.length === 0) {
          dispatch(updateUserId(userInfo?.user?.id));
        } else {
          dispatch(updateUserId(result?.[0]?.id));
        }
        dispatch(toggleDataLoading(false))
        
        dispatch(updateUsers(result));
      }
    }
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch(toggleAuth({ isLogin: false, userInfo: null }));
      toast.dismiss();
      toast.error("Your session is expired");
      return;
    }
    toast.dismiss();
    toast.error("Something went wrong");
    console.error(error);
  } finally {
    // Ensure loading state is reset once and only after all processing is complete
    dispatch(toggleDataLoading(false));
   // console.log(dataLoading)
  }
};

  useEffect(() => {
  getUsers();
  },[])

  return (
    <div className="relative min-h-screen overflow-hidden">
      {userInfo?.user?.is_verified === false ? <AlertBar /> : <></>}
      <GoToTop />
      <div className="flex min-h-screen text-white">
        {/* <div className="fixed z-50">
          <Sidebar isopen={isopen} toggle={toggle} />
        </div>
        <div
          className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 ${
            isopen ? "z-40" : "hidden"
          }`}
          onClick={toggleSidebar}
        /> */}
        <div
          className={`flex-grow min-h-screen  overflow-hidden ${
            theme === "dark" ? "bg-dashboardDarkMode" : "bg-white"
          }`}
        >
          <DashboardNavbar />
          <div className="flex justify-center overflow-x-hidden overflow-auto h-[calc(100vh-94px)] ">
            <div className="w-full flex flex-col items-center">
              <Outlet />
            </div>
            {/* <div
              className={`w-1/4 flex-grow p-5 lg:block hidden fixed right-4`}
            >
              <SideBar />
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="bg-red-600 text-white h-12 w-12 absolute bottom-10 right-10 flex justify-center items-center cursor-pointer z-50 text-2xl ease-in-out transition-all"
        style={{ borderRadius: "50%" }}
        onClick={toggleChat}
      >
       {isOpen ? <RxCross1 /> : <MdOutlineChat />} 
      </div>
      {isOpen && (
        <div className="fixed bottom-24 right-8 w-80 max-w-full bg-white rounded-lg shadow-xl border-black border">
          <div className="flex items-center justify-between px-4 py-3 bg-red-600 text-white rounded-t-lg">
            <h3 className="font-semibold">Chat Support</h3>
           
          </div>

          <div className="p-4 max-h-72 overflow-y-auto">
            <p>Hello! How can we help you?</p>
          </div>

          <div className="flex items-center p-4 border-t border-gray-300">
            <input
              type="text"
              placeholder="Type a message..."
              className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
            />
            <button className="bg-red-600 text-white px-4 py-2 rounded-md ml-2 hover:bg-red-800 transition">
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;

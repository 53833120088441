import React, { useState, useEffect, useRef } from "react";
import { MdElectricBolt, MdMenu, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useNavigate } from "react-router-dom";
import styles from "./navbar.module.css";
import logo from "../../../assets/bugtrack.png";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import {
  FaWindows,
  FaApple,
  FaLinux,
  FaDownload,
  FaSun,
  FaMoon,
  FaDollarSign,
} from "react-icons/fa";
import { FiBell, FiSettings, FiLogOut } from "react-icons/fi";
import { IoHourglassOutline } from "react-icons/io5";
import profile from "../../../assets/face1.jpg";
import { toast } from "react-toastify";
import { fetchApi } from "../../../utlis/axios";
import Preferences from "../Settings/preferences/Preferences";
import HourlyRate from "../Settings/hourlyRate/HourlyRate";
import { toggleTheme } from "../../../Redux/Slices/theme-slice";
import Reports from "../reports/Reports";

const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [activeDropdown, setActiveDropdown] = useState(null);
  const plans = useSelector((state) => state.allPlans.plans);
  const isLoading = useSelector((state) => state.allPlans.plansIsLoading);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [showPrefrences, setShowPrefrences] = useState();
  const [showHourRate, setShowHourRate] = useState();
  const [showReport, setShowReport] = useState();
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setActiveDropdown(null);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (dropdown) => {
    setActiveDropdown((prev) => (prev === dropdown ? null : dropdown));
  };

  const handleLogout = () => {
    dispatch(toggleAuth({ isLogin: false, userInfo: null }));
    navigate("/");
  };

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };
console.log(userInfo.user)
  return (
    <div
      className={` ${styles.header} ${
        theme === "dark" ? `${styles.dark_mode}` : `${styles.light_mode}`
      }`}
    >
      {/* {showPrefrences && (
        <div className={styles.modalOverlay}>
          <div
            className={`${styles.modal} ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#e5e5e5] text-black"
            }`}
          >
            <Preferences setShowPrefrences={setShowPrefrences} />
          </div>{" "}
        </div>
      )} */}
      {showHourRate && (
        <div className={styles.modalOverlay}>
          <div
            className={`${styles.modal} ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#e5e5e5] text-black"
            }`}
          >
            <HourlyRate setShowHourRate={setShowHourRate} />
          </div>{" "}
        </div>
      )}

      <div
        className={`${styles.sticky_navbar} ${
          theme === "dark" ? `${styles.dark_mode}` : `${styles.light_mode}`
        }`}
      >
        <div className={`${styles.header_top}`}>
          <div className="flex gap-2 items-center">
            <NavLink to="/">
              <div className={`${styles.logo}`}>
                <img src={logo} className="h-6 w-6 mb-1" />
                <span>
                  <span className="text-[#FF0000] ">ug</span>Trackin
                </span>
              </div>
            </NavLink>
            <span className={`text-3xl text-gray-500 ${styles.logobar}`}>
              /
            </span>
            <span className={styles.profileName}>
              {userInfo?.user?.first_name} {userInfo?.user?.last_name}
            </span>
          </div>
          <div className={styles.nav_toggle_btn}>
            <div
              className={`text-sm ${styles.menuContainer2}`}
              ref={dropdownRef}
            >
              {userInfo?.user?.role?.name !== "Time Reporter" && (
                <div className={styles.dropdownWrapper}>
                  <button
                    onClick={() => toggleDropdown("subscription")}
                    className={`${styles.menuItem} ${styles.iconButton}`}
                  >
                    <FaDollarSign />
                  </button>
                  {activeDropdown === "subscription" && (
                    <div className={styles.dropdownContent}>
                      <h6 className={styles.dropdownTitle}>
                        Subscription Plans
                      </h6>
                      {isLoading ? (
                        <div>Loading....</div>
                      ) : (
                        plans.map((plan, index) => (
                          <div
                            className={`flex items-center justify-between gap-3 mb-2 ${
                              plan?.id === userInfo?.user?.plan?.id
                                ? "text-green-500"
                                : ""
                            }`}
                          >
                            <p className={styles.notificationText}>
                              {plan.name}
                            </p>
                            <p>
                              {plan?.amount} {plan?.currency}/month
                            </p>
                          </div>
                        ))
                      )}
                    </div>
                  )}
                </div>
              )}

              <div className={styles.dropdownWrapper}>
                <button
                  onClick={() => toggleDropdown("download")}
                  className={`${styles.menuItem} ${styles.iconButton}`}
                >
                  <FaDownload />
                </button>
                {activeDropdown === "download" && (
                  <div className={styles.dropdownContent}>
                    <h6 className={styles.dropdownTitle}>Downloads</h6>
                    <p className={styles.notificationText}>
                      <FaWindows /> Windows Agent
                    </p>
                    <p className={styles.notificationText}>
                      <FaApple /> Mac OS Agent
                    </p>
                    <p className={styles.notificationText}>
                      <FaLinux /> Linux Agent
                    </p>
                  </div>
                )}
              </div>
              {/* <div className={styles.dropdownWrapper}>
               <button
                  onClick={() => toggleDropdown("notifications")}
                  className={`${styles.menuItem} ${styles.iconButton}`}
                >
                  <FiBell />
                  <span className={styles.notificationCount} />
                </button>
                {activeDropdown === "notifications" && (
                  <div className={styles.dropdownContent}>
                    <h6 className={styles.dropdownTitle}>Notifications</h6>
                    <p className={styles.notificationText}>Up coming</p>
                  </div>
                )}
              </div>
              */}
            </div>
            <button
              className="text-3xl hover:text-[#ff0000]"
              onClick={toggleNavVisibility}
            >
              {isNavVisible ? <MdClose /> : <MdMenu />}
            </button>
          </div>
        </div>
        <div
          className={`${styles.nav} ${styles.mobileNavbar} ${
            isNavVisible ? `${styles.visible}` : ""
          } ${
            isNavVisible
              ? theme === "dark"
                ? `${styles.navdark}`
                : `${styles.navlight}`
              : ""
          } text-sm`}
        >
          <NavLink
            to="/dashboard/summary"
            className={({ isActive }) =>
              isActive ? `${styles.active}` : styles.navLink
            }
          >
            Summary
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              isActive ? ` ${styles.active}` : styles.navLink
            }
            to="/dashboard/activity"
          >
            Activity
          </NavLink>
          {userInfo?.user?.role?.name === "Admin" && (
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.active}` : styles.navLink
              }
              to="/dashboard/users"
            >
              Users
            </NavLink>
          )}
          {userInfo?.user?.role?.name !== "Time Reporter" && (
            <>
              <NavLink
                className={({ isActive }) =>
                  isActive ? `${styles.active}` : styles.navLink
                }
                to="/dashboard/analytics_charts"
              >
                Analytics & Charts
              </NavLink>
              <NavLink
                className={({ isActive }) =>
                  isActive
                    ? `${styles.navLink} ${styles.active}`
                    : styles.navLink
                }
                to="/dashboard/reports"
              >
                Reports
              </NavLink>
            </>
          )}
          <div className={`flex justify-between w-full `}>
            <span>Theme</span>
            <div className={styles.toggleContainer}>
              <button
                className={`${styles.toggleButton} ${
                  theme === "light" ? `${styles.active}` : ""
                } `}
                onClick={() => {
                  dispatch(toggleTheme({ theme: "light" }));
                }}
              >
                <FaSun />
              </button>
              <button
                className={`${styles.toggleButton} ${
                  theme === "dark" ? `${styles.active}` : ""
                }`}
                onClick={() => {
                  dispatch(toggleTheme({ theme: "dark" }));
                }}
              >
                <FaMoon />
              </button>
            </div>
          </div>
          <NavLink
            to="/dashboard/setting/personalinformation"
            className={`flex justify-between w-full `}
          >
            <span>Settings</span>
            <FiSettings />
          </NavLink>
          <div
            className={`flex justify-between w-full cursor-pointer hover:text-[#ff0000]`}
            onClick={handleLogout}
          >
            <span>Log Out</span>
            <FiLogOut />
          </div>
        </div>
        <div className={styles.menuContainer} ref={dropdownRef}>
          {userInfo?.user?.role?.name !== "Time Reporter" && (
            <div className={styles.dropdownWrapper}>
              <Link
                to="/dashboard/changeplan"
                // onClick={() => toggleDropdown("subscription")}
                className={` ${styles.menuItem} ${styles.iconButton} text-sm`}
              >
                Subscription
              </Link>
              {activeDropdown === "subscription" && (
                <div className={styles.dropdownContent}>
                  <h6 className={styles.dropdownTitle}>Subscription Plans</h6>
                  {isLoading ? (
                    <div>Loading....</div>
                  ) : (
                    plans.map((plan, index) => (
                      <div
                        className={`flex items-center justify-between gap-3 mb-2 ${
                          plan?.id === userInfo?.user?.plan?.id
                            ? "text-green-500"
                            : ""
                        }`}
                      >
                        <p className={styles.notificationText}>{plan.name}</p>
                        <p>{plan?.description}</p>
                        {/* {plan?.id === userInfo?.user?.plan?.id && (
                        <span className="h-3 w-3 bg-green-500 rounded-full text-xs text-white flex justify-center items-center">
                          <TiTick />
                        </span>
                      )} */}
                      </div>
                    ))
                  )}
                </div>
              )}
            </div>
          )}

          {userInfo?.user?.role?.name !== "Time Reporter" && (
            <Link
              to="/dashboard/updatecompany"
              className={` ${styles.menuItem} ${styles.iconButton} text-sm`}
            >
              Company
            </Link>
          )}
           {userInfo?.user?.role?.name !== "Time Reporter" && (
            <Link
              to="/dashboard/board"
              className={` ${styles.menuItem} ${styles.iconButton} text-sm`}
            >
              Board
            </Link>
          )}

          <NavLink
            className={` ${styles.menuItem} ${styles.iconButton} text-sm`}
          >
            Help
          </NavLink>
          {userInfo?.user?.role?.name !== "Time Reporter" && (
            <Link
              to="/dashboard/invoices"
              className={` ${styles.menuItem} ${styles.iconButton} text-sm`}
            >
              Payments
            </Link>
          )}

          <NavLink
            className={` ${styles.menuItem} ${styles.iconButton} text-sm`}
          >
            Docs
          </NavLink>
          <div className={styles.dropdownWrapper}>
            <button
              onClick={() => toggleDropdown("download")}
              className={`${styles.menuItem} ${styles.iconButton}`}
            >
              <FaDownload />
            </button>
            {activeDropdown === "download" && (
              <div className={styles.dropdownContent}>
                <h6 className={styles.dropdownTitle}>Downloads</h6>
                <p className={styles.notificationText}>
                  <FaWindows /> Windows Agent
                </p>
                <p className={styles.notificationText}>
                  <FaApple /> Mac OS Agent
                </p>
                <p className={styles.notificationText}>
                  <FaLinux /> Linux Agent
                </p>
              </div>
            )}
          </div>
          <div className={styles.dropdownWrapper}>
            <div
              className={styles.profile}
              onClick={() => toggleDropdown("profile")}
            >
              <img className={styles.profileImg} src={profile} alt="Profile" />
            </div>
            {activeDropdown === "profile" && (
              <div className={styles.dropdownContent2}>
                <h6 className={`px-3 ${styles.dropdownTitle}`}>
                  {userInfo?.user?.first_name} {userInfo?.user?.last_name}
                </h6>
                {}
               {/*userInfo?.user?.role?.name === "Time Reporter" && (*/}
                  <>
                    <div className={styles.dropdownItem}>
                      <span
                        className={`flex justify-between w-full ${styles.dropdownItem}`}
                      >
                        {userInfo?.user?.payment_type} Rate <span>{userInfo?.user?.payment_amount} {userInfo?.user?.currency}</span>
                      </span>
                    </div>

                    <hr className="mt-2 mb-2" />
                  </>
               

                <div className={styles.dropdownItem}>
                  <div
                    className={`flex justify-between w-full ${styles.dropdownItem}`}
                  >
                    <span>Theme</span>
                    <div className={styles.toggleContainer}>
                      <button
                        className={`${styles.toggleButton} ${
                          theme === "light" ? `${styles.active}` : ""
                        } `}
                        onClick={() => {
                          dispatch(toggleTheme({ theme: "light" }));
                        }}
                      >
                        <FaSun />
                      </button>
                      <button
                        className={`${styles.toggleButton} ${
                          theme === "dark" ? `${styles.active}` : ""
                        }`}
                        onClick={() => {
                          dispatch(toggleTheme({ theme: "dark" }));
                        }}
                      >
                        <FaMoon />
                      </button>
                    </div>
                  </div>
                </div>
                <div className={styles.dropdownItem}>
                  <NavLink
                    to="/dashboard/setting/personalinformation"
                    className={`flex justify-between w-full ${styles.dropdownItem}`}
                  >
                    <span>Settings</span>
                    <FiSettings />
                  </NavLink>
                </div>
                <hr className="mt-2 mb-2" />

                <div className={styles.dropdownItem}>
                  <div
                    className={`flex justify-between w-full ${styles.dropdownItem}`}
                    onClick={handleLogout}
                  >
                    <span>Log Out</span>
                    <FiLogOut />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.navLinkMain}>
        <NavLink
          to="/dashboard/summary"
          className={({ isActive }) =>
            isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
          }
        >
          Summary
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
          }
          to="/dashboard/activity"
        >
          Activity
        </NavLink>
        {userInfo?.user?.role?.name === "Admin" && (
          <NavLink
            className={({ isActive }) =>
              isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
            }
            to="/dashboard/users"
          >
            Users
          </NavLink>
        )}
        {userInfo?.user?.role?.name !== "Time Reporter" && (
          <>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
              to="/dashboard/analytics_charts"
            >
              Analytics & Charts
            </NavLink>

            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
              to="/dashboard/reports"
            >
              Reports
            </NavLink>
          </>
        )}
        {userInfo?.user?.role?.name === "Time Reporter" && (
          <>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
              to="/dashboard/overtime"
            >
              Overtime
            </NavLink>
          </>
        )}
        {userInfo?.user?.role?.name === "Time Reporter" && (
          <>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
              to="/dashboard/timeoffs"
            >
              TimeOffs
            </NavLink>
            <NavLink
              className={({ isActive }) =>
                isActive ? `${styles.navLink} ${styles.active}` : styles.navLink
              }
              to="/dashboard/timesheet"
            >
              Time Sheet
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
};

export default Navbar;

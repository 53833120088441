import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ParticlesBackground from "../../ParticalsBackground";
import GlowCard from "../glow-card";
import Loader from "../../../Loader/Loader";
import { togglePlan } from "../../../../Redux/Slices/plans-slice";
import { Modal, Popconfirm, Spin } from "antd";
import ContactUsViaMail from "../../contactUs/ContactUsViaMail";
import Calendly from "./Calendly";

const SubscriptionPage = (props) => {
  const { userInfo } = useSelector((state) => state?.auth || {});
  const plans = useSelector((state) => state.allPlans.plans);
  const isLoading = useSelector((state) => state.allPlans.plansIsLoading);
  const navigate = useNavigate();
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const [childComponent, setChilComponent] = useState(null);
  const handleCancel = () => {
    setChilComponent(null);
  };

  const onClickSignup = (plan) => {
    console.log('i am clicking on signup')
    if (plan === "enterprise") {
      navigate("/contact-us");
      return;
    } else {
      dispatch(togglePlan({ plan: plan }));
      navigate("/signup");
    }
  };

  const handlePlans = (plan) => {
    dispatch(togglePlan({ plan: plan }));
    navigate("/signup");
  };

  const features = {
    Basic: [
      "Time tracker",
      "Activity level",
      "Limited screenshots",
      "Limited reports",
      "Idle timeout",
      "Two-day email support",
    ],
    Standard: [
      "Unlimited reports",
      "Project",
      "Milestone",
      "Task",
      "Expenses",
      "One day email support",
      "Company setup",
    ],
    Premium: [
      "Unlimited screenshots",
      "Company template management",
      "Company user invoices",
      "Overtime",
      "Time off and holidays",
      "Timesheet approval",
      "Chat support",
      "Payments & payroll",
      "Admin dashboard",
      "Finance & reporting dashboard",
      "One day email support",
    ],
    Enterprise: [
      "Everything in Premium",
      "Fixed yearly payment",
      "For above 100 users",
      "chat support",
      "one day email support",
    ],
  };

  const getPrice = (str) => {
    const input = str;
    const separator = "per";
    // Using `split`
    const parts = input.split(separator);
    const beforePer = parts[0].trim(); // Text before 'per'
    return "$" + beforePer.replace("$", "");
  };

  const onViaMail = (plan) => {
    setChilComponent(
      <Modal
        width={500}
        title="Contact Us"
        open={true}
        height={50}
        onCancel={handleCancel}
        footer={null}
      >
        <ContactUsViaMail parent="plans" />
      </Modal>
    );
  };

  return (
    <>
      <ParticlesBackground id="particaljs" />
      {childComponent}
      <section
        className={`${styles.pricingSection} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
        id="pricingPlans"
      >
        <div className="plans-container">
          <h2 className={`main-heading text-center py-8 `}>
            Subscription Plans
          </h2>
          {isLoading && plans.length === 0 ? (
            <Loader />
          ) : (
            <div className={`gap-6 ${styles.pricingPlans} items-strech`}>
              {plans.map((plan, index) => (
                <div
                  key={plan.id}
                  className={`min-h-[640px] w-[285px] ${styles.planCard}`}
                >
                  <GlowCard
                    key={plan.id}
                    identifier={index}
                    plan={plan}
                    page={props.parent}
                    className="h-full"
                  >
                    <div className={`${styles.planDescriptionCard}`}>
                      <h3 className={styles.planTitle}>{plan?.name}</h3>
                      {/* <p className={styles.planDescription}>{plan?.description}</p>  */}
                      {plan.name === "Enterprise" ? (
                        <>
                          <span
                            style={{
                              fontSize: "14px",
                              marginTop: "36px",
                              marginBottom: "9px",
                            }}
                          >
                            <b>{plan.description}</b>
                          </span>
                        </>
                      ) : (
                        <span className={styles.planPrice}>
                          {getPrice(plan?.description).split(".").length > 0
                            ? getPrice(plan?.description).split(".")[0]
                            : ""}
                          <sup style={{ fontSize: "14px" }}>
                            {plan.amount.split(".").length > 0
                              ? "." + plan.amount.split(".")[1]
                              : ""}
                          </sup>
                          <sub style={{ fontSize: "14px" }}>
                            {"/user/" + plan.interval}
                          </sub>
                        </span>
                      )}
                      {plan.name === "Enterprise" ? (
                        <Popconfirm
                          title="Get in touch"
                          description=""
                          okText={<Calendly />}
                          cancelText="Via email"
                          //onConfirm={()=>onViaMeeting(plan)}
                          onCancel={() => onViaMail(plan)}
                        >
                          <div className="flex w-full justify-center items-center">
                            <button
                              style={{
                                width: plan.name === "Enterprise" ? "70%" : "",
                                
                              }}
                              className={`${styles.planButton} heroButton`}
                            >
                              {plan.name === "Enterprise"
                                ? "Get in touch"
                                : "Sign Up"}
                            </button>
                          </div>
                        </Popconfirm>
                      ) : (
                        <div className="flex items-center justify-center">
                          <button
                            style={{
                              padding: '9px 30px'
                            }}
                            className={`${styles.planButton} heroButton`}
                            onClick={() => handlePlans(plan?.id)}
                          >
                            Signup
                          </button>
                        </div>
                      )}
                    </div>
                    <div
                      className={`${styles.plan}`}
                      style={{ color: theme === "light" ? "black" : "white" }}
                    >
                      <ul>
                        {features[plan.name].map((feature, key) => (
                          <li
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <span
                              style={{ fontSize: "24px", color: "#C4242C" }}
                            >
                              ✔
                            </span>
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>
                      <div
                        style={{
                          marginTop: "auto",
                          position: "absolute",
                          bottom: "24px",
                        }}
                      >
                        <Link
                          to="/see-all-features"
                          className={styles.seeFeatures}
                        >
                          <span>See all features</span>
                        </Link>
                      </div>
                    </div>
                  </GlowCard>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default SubscriptionPage;

import React, { useState, useEffect, useRef } from "react";
import styles from "./overtime.module.css";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";

const CreateOvertime = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [hours, setHours] = useState("");
  const [loading, setLoading] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);

  // Ref to prevent repeated fetch calls
  const isFetchedRef = useRef(false);

  const handleCreate = async () => {
    if (!description || !type || !amount || !hours) {
      toast.error("Please fill all fields");
    } else {
      try {
        setUpdating(true);
        const result = await fetchApi.post(
          "employee-overtimes/",
          {
            description,
            hours,
            status: "pending",
            amount,
            type,
            user: userInfo?.user?.id,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.access_token}`,
            },
          }
        );

        if (result.status === 200) {
          GetRequest();
          setDescription("");
          setType("");
          setHours("");
          setAmount("");
          setIsModalOpen(false);
          toast.success("Request created successfully.");
        }
      } catch (error) {
        toast.dismiss();
        toast.error(error.message || "Error creating request");
      } finally {
        setUpdating(false);
      }
    }
  };

  const GetRequest = async () => {
    setLoading(true);
    try {
      const res = await fetchApi.get(`employee-overtimes/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 200) {
        setRequestData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message || "Error fetching data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isFetchedRef.current) {
      isFetchedRef.current = true; // Prevent subsequent fetch calls
      GetRequest();
    }
  }, []);

  const handleNext = async () => {
    setLoading(true);

    try {
      const res = await fetchApi.get(`${next}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 200) {
        setRequestData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const handlePrevious = async () => {
    setLoading(true);

    try {
      const res = await fetchApi.get(`${prev}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 200) {
        setRequestData(res.data.results);
        setNext(res.data.next);
        setPrev(res.data.previous);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <style>
        {`
          .reportInput {
            width: 100%;
            padding: 10px;
            border: ${
              theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"
            };
            border-radius: 5px;
            margin-top: 5px;
            box-sizing: border-box; 
            overflow-y: auto;
            background-color: transparent;
          }
          .reportInput option {
            color: black;
          }
        `}
      </style>
      <div
        className={`${styles.main_Heading} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        Overtime
      </div>
      <hr className="w-full" />
      <div className={styles.main_container}>
        <div className={styles.actions}>
          {isModalOpen && (
            <div className={styles.modalOverlay}>
              <div
                className={`${styles.modal} ${
                  theme === "dark"
                    ? "bg-[#212529] text-white"
                    : "bg-[#e5e5e5] text-black"
                }`}
              >
                <div className={styles.modalHeader}>
                  <h2 className="font-semibold">Create Overtime Request</h2>
                  <button
                    className={styles.closeButton}
                    onClick={() => setIsModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <div className={`${styles.modalContent}`}>
                  <div className="text-left mt-3">
                    <div className="md:flex justify-between block">
                      <div className="w-full md:w-[45%]">
                        <div className={styles.formGroup}>
                          <label>Description*</label>
                          <textarea
                            className={styles.reportInput}
                            type="text"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder="Enter description"
                            required
                          />
                        </div>
                        <div className={`flex flex-col`}>
                          <label>Hours</label>
                          <input
                            type="number"
                            placeholder="Hours"
                            className={styles.reportInput}
                            value={hours}
                            onChange={(e) => setHours(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="w-full md:w-[45%]">
                        <div className={styles.formGroup}>
                          <label>Amount*</label>
                          <input
                            className={styles.reportInput}
                            type="number"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                            placeholder="Enter amount"
                            required
                          />
                        </div>
                        <div className="flex flex-col">
                          <label htmlFor="typeInput">Type</label>
                          <input
                            list="typeOptions"
                            id="typeInput"
                            type="text"
                            placeholder="Type of overtime"
                            className={styles.reportInput}
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          />
                          <datalist id="typeOptions">
                            <option value="Regular Overtime" />
                            <option value="Weekend Overtime" />
                            <option value="Holiday Overtime" />
                            <option value="Night Shift Overtime" />
                          </datalist>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.footer}>
                    <button
                      className="heroButton"
                      onClick={handleCreate}
                      disabled={updating}
                    >
                      {updating ? "Creating..." : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            className="mb-5 heroButton"
            onClick={() => setIsModalOpen(true)}
          >
            Create Request
          </button>
        </div>
      </div>
      {loading ? (
        <Loader />
      ) : (
        requestData.length > 0 && (
          <>
            <div className={`${styles.tableResponsive} max-w-screen-lg`}>
              <table
                className={`${theme === "dark" ? "text-white" : "text-black"} ${
                  styles.table
                }`}
              >
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Status</th>
                    <th>Hours</th>
                    <th>Amount</th>
                    <th>Description</th>
                  </tr>
                </thead>
                <tbody>
                  {requestData.map((item, i) => (
                    <tr
                      key={i}
                      className={`border-b ${
                        theme === "dark"
                          ? "hover:bg-gradient-to-r from-[#fff3] to-[#fff0]"
                          : "hover:bg-gradient-to-r from-[#6d6d6d33] to-[#fff0]"
                      }`}
                    >
                      <td>
                        {userInfo?.user?.first_name} {userInfo?.user?.last_name}
                      </td>
                      <td>
                        {item?.status === "approved" ? (
                          <span className="bg-green-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                            {item?.status}
                          </span>
                        ) : item?.status === "rejected" ? (
                          <span className="bg-red-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                            {item?.status}
                          </span>
                        ) : (
                          <span className="bg-yellow-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                            {item?.status}
                          </span>
                        )}
                      </td>
                      <td>{item?.hours}</td>
                      <td>{item?.amount}</td>
                      <td>{item?.description.slice(0, 30)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="w-full  mt-2 justify-between flex">
              <span>
                {prev && (
                  <button className="heroButton block" onClick={handlePrevious}>
                    Previous
                  </button>
                )}
              </span>
              <span>
                {next && (
                  <button className="heroButton block" onClick={handleNext}>
                    Next
                  </button>
                )}
              </span>
            </div>
          </>
        )
      )}
    </>
  );
};

export default CreateOvertime;

import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "../Dashboard/users/user.module.css";
import { useSelector } from "react-redux";
import { fetchApi } from "../../utlis/axios";
import Loader from "../Loader/Loader";
import { FaDownload, FaEye } from "react-icons/fa";

import HourlyRate from "../Dashboard/Settings/hourlyRate/HourlyRate";
import dayjs from 'dayjs';

const Invoices = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showHourRate, setShowHourRate] = useState();
  const [invoices, setInvoices] = useState([]);
let isFetched = false;
  const getInvoice = async () => {
if(isFetched) return;
isFetched = true;
    setLoading(true);

    try {
      const result = await fetchApi.get("admin-invoice/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (result.status === 200) {
        //toast.success("Updated Successfully!");

        setInvoices(result.data);
        //props.onCompleted(result);
      }
      setLoading(false);
    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.error || "failed");
      if (error.response.data.detail) {
        toast.error(error.response.data.detail)
      }

      //toast.error(error?.response?.data?.error || "failed");
      setLoading(false);
    }
  };
  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <>
      <ToastContainer />
      <style>
        { `
      .input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
   border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"};
  box-sizing: border-box;
  background-color: transparent;
  option{
     color: #000000; 
    }
}
      `}
      </style>
      { showHourRate && (
        <div className={ styles.modalOverlay }>
          <div
            className={ `${styles.modal} ${theme === "dark"
              ? "bg-[#212529] text-white"
              : "bg-[#e5e5e5] text-black"
              }` }
          >
            <HourlyRate setShowHourRate={ setShowHourRate } />
          </div>{ " " }
        </div>
      ) }
      <div
        className={ `${styles.main_Heading} ${theme === "dark" ? "text-white" : "text-black"
          }` }
      >
        Payments
      </div>
      <hr className="w-full" />
      <div
        className={ ` ${styles.outlet}  ${theme === "dark" ? "text-white" : "text-black"
          }` }
      >
        { isModalOpen && (
          <div className={ styles.modalOverlay }>
            <div
              className={ `${styles.modal} ${theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#e5e5e5] text-black"
                }` }
            >


            </div>
          </div>
        ) }
        { loading ? (
          <Loader />
        ) : (
          <>

            <div className="overflow-x-auto md:mt-10 mt-4">
              <div className={ styles.tableResponsive }>
                <table className={ styles.table }>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount Paid</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    { invoices.map((invoice, i) => (
                      <tr key={ invoice?.id }>

                        <td>
                        { dayjs(invoice?.date).format('DD MMMM YYYY') }
                        </td>

                        <td> ${ invoice?.amount_paid }</td>

                        <td>
                          <span className="flex gap-2 items-center">
                            <a style={ { cursor: "pointer" } } href={ invoice?.url } target="__blank" className="text-success"><FaDownload

                              className={ `cursor-${userInfo?.user?.role?.name === "Admin"
                                ? "pointer"
                                : "not-allowed"
                                }` }
                            /></a>


                          </span>
                        </td>
                      </tr>
                    )) }
                  </tbody>
                </table>
              </div>
            </div>
          </>
        ) }
      </div>
    </>
  );
};

export default Invoices;

import React from "react";
import styles from "./previewModal.module.css";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";

const PreviewModal = ({
  onClose,
  imgSrc,
  handlePrevious,
  handleNext,
  currentIndex,
  slotDataLength,
}) => {
  return (
    <div className={styles.modalOverlay}>
    {/*  <Swiper
        loop={true}
        onSlidePrevTransitionStart={handlePrevious} // Trigger handlePrevious when swiping left
        onSlideNextTransitionStart={handleNext}
        className={styles.swiperContainer}
      >
        <SwiperSlide className={styles.slideContainer}>*/}
          <button
            className={`${styles.arrowButton} ${styles.leftArrow} ${
              currentIndex === 0 ? "hidden" : "block"
            }`}
            onClick={handlePrevious}
          >
            <FaArrowLeft />
          </button>
          {imgSrc ? (
            <img src={imgSrc} className={styles.modalImage} alt="Slide" />
          ) : (
            <div className={styles.disabledMonitor}>BugTrack agent disable</div>
          )}
          <button
            className={`${styles.arrowButton} ${styles.rightArrow} ${
              currentIndex === slotDataLength - 1 ? "hidden" : "block"
            }`}
            onClick={handleNext}
          >
            <FaArrowRight />
          </button>
        {/*</SwiperSlide>
      </Swiper>*/}
      <button className={styles.closeButton} onClick={onClose}>
        <IoMdCloseCircleOutline />
      </button>
    </div>
  );
};

export default PreviewModal;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { fetchApi } from "../../../utlis/axios";

const TicketModal = ({ setTicketModal, projects }) => {
  const { theme } = useSelector((state) => state.theme);
  const users = useSelector((state) => state.allUsers?.filterUsers);
  const { userInfo } = useSelector((state) => state.auth || {});
  const [statusOptionDropdown, setStatusOptionDropdown] = useState(false);
  const [statusOptionValue, setStatusOptionValue] = useState("todo");
  const [piortyDropdown, setPiortyDropdown] = useState(false);
  const [priorityValue, setPriorityValue] = useState("high");
  const statusOptions = [
    "todo",
    "done",
    "in-review",
    "rejected",
    "in-progress",
  ];
  const piortyOptions = ["low", "medium", "high"];
  const [projectsDropdown, setProjectDropdown] = useState(false);
  const [selectedProject, setSelectedProject] = useState("");
  const [userDropDown, setUserDropDown] = useState(false);
  const [selectedUser, setSelectedUser] = useState("");
  const [milestones, setMilestones] = useState([]);
  const [selctedMilestone, setSelectedMilestone] = useState("");
  const [milestoneDropDown, setMilestoneDropDown] = useState(false);
  const [ticketData, setTicketData] = useState({
    title: "",
    sprint: "",
    description: "",
    status: statusOptionValue,
    time_allotted: 0,
    priority: priorityValue,
    type: "",
    started_at: "",
    remaining_time: 0,
    project: selectedProject,
    milestone: "",
    developer: selectedUser,
    attachment: [],
    comments: [{name:'comment'}],
  });
  console.log(selectedProject, selectedUser);

  const getMilestone = async () => {
    try {
      const res = await fetchApi.get(`/project-milestones/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setMilestones(res.data.results);
      }
    } catch (error) {
      toast?.error(error.message);
    }
  };
  useEffect(() => {
    getMilestone();
  }, []);

  const handleSubmit = async () => {
    try {
    
      // Convert ticketData to FormData
      const formData = new FormData();
      formData.append("title", ticketData.title);
      formData.append("sprint", ticketData.sprint);
      formData.append("description", ticketData.description);
      formData.append("status", ticketData.status);
      formData.append("time_allotted", ticketData.time_allotted);
    
      formData.append("started_at", ticketData.started_at);
      formData.append("priority", ticketData.priority);
      formData.append("type", ticketData.type);
      formData.append("started_at", ticketData.started_at);
      formData.append("remaining_time", ticketData.remaining_time);
      formData.append("project", selectedProject);
      formData.append("milestone", selctedMilestone);
      formData.append("developer", selectedUser);
      formData.append("comments", JSON.stringify(["Your comment here","Your comment"]));


      // Append attachments (if any)
      if (ticketData.attachment?.length > 0) {
        ticketData.attachment.forEach((file, index) => {
          formData.append(`attachment[${index}]`, file);
        });
      }

      const res = await fetchApi.post("/project-tickets/", formData, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 201) {
        toast?.dismiss();
        toast?.success("Ticket created successfully");
        // Reset ticket data after successful submission
        setTicketData({
          title: "",
          sprint: "",
          description: "",
          status: statusOptionValue,
          time_allotted: 0,
          priority: priorityValue,
          type: "",
          started_at: "",
          remaining_time: 0,
          project: selectedProject,
          milestone: "",
          developer: selectedUser,
          attachment: [],
          comments: "",
        });
      }
    } catch (error) {
      toast?.dismiss();
      toast?.error(error.message);
    }
  };

  return (
    <div>
      <>
        {/* Overlay */}
        <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>

        {/* Modal Content */}
        <div className="fixed inset-0 flex items-center justify-center z-20 ">
          <div
            className={` p-6 rounded-lg shadow-lg max-w-screen-md w-full ${
              theme === "dark"
                ? "bg-darkMode text-white"
                : "bg-lightMode text-black"
            }`}
          >
            <div className="flex justify-between">
              <div className="flex gap-2 items-center">
                <IoIosArrowForward className="w-4 h-4" />
                <h2 className="text-base font-semibold">New Ticket</h2>
              </div>
              <IoClose
                className="w-6 h-6"
                onClick={() => {
                  setTicketModal(false);
                }}
              />
            </div>
            <form className="grid grid-cols-2 my-4 gap-4">
              <div className="flex flex-col gap-1">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  className={`text-base font-normal p-2 rounded-md border bg-transparent outline-none w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300 border-white"
                      : "placeholder:text-gray-500 border-black"
                  }`}
                  value={ticketData.title}
                  onChange={(e) => {
                    setTicketData({
                      ...ticketData,
                      title: e.target.value,
                    });
                  }}
                  placeholder="Enter title of ticket"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="title">Sprint</label>
                <input
                  type="text"
                  className={`text-base font-normal p-2 rounded-md border bg-transparent outline-none w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300 border-white"
                      : "placeholder:text-gray-500 border-black"
                  }`}
                  value={ticketData.sprint}
                  onChange={(e) => {
                    setTicketData({
                      ...ticketData,
                      sprint: e.target.value,
                    });
                  }}
                  placeholder="Sprint"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="title">Type</label>
                <input
                  type="text"
                  className={`text-base font-normal p-2 rounded-md border bg-transparent outline-none w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300 border-white"
                      : "placeholder:text-gray-500 border-black"
                  }`}
                  value={ticketData.type}
                  onChange={(e) => {
                    setTicketData({
                      ...ticketData,
                      type: e.target.value,
                    });
                  }}
                  placeholder="Enter title of ticket"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="title">Description</label>
                <textarea
                  type="text"
                  rows={1}
                  className={`text-base font-normal p-2 rounded-md border bg-transparent outline-none w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300 border-white"
                      : "placeholder:text-gray-500 border-black"
                  }`}
                  value={ticketData.description}
                  onChange={(e) => {
                    setTicketData({
                      ...ticketData,
                      description: e.target.value,
                    });
                  }}
                  placeholder="Enter description of ticket"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="title">Time allotted</label>
                <input
                  type="number"
                  className={`text-base font-normal p-2 rounded-md border bg-transparent outline-none w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300 border-white"
                      : "placeholder:text-gray-500 border-black"
                  }`}
                  value={ticketData.time_allotted}
                  onChange={(e) => {
                    setTicketData({
                      ...ticketData,
                      time_allotted: e.target.value,
                    });
                  }}
                  placeholder="Enter allotted time"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="title">Remaining Time</label>
                <input
                  type="number"
                  className={`text-base font-normal p-2 rounded-md border bg-transparent outline-none w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300 border-white"
                      : "placeholder:text-gray-500 border-black"
                  }`}
                  value={ticketData.remaining_time}
                  onChange={(e) => {
                    setTicketData({
                      ...ticketData,
                      remaining_time: e.target.value,
                    });
                  }}
                  placeholder="Enter alloted time"
                />
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="title">Start date</label>
                <input
                  type="date"
                  className={`text-base font-normal p-2 rounded-md border bg-transparent outline-none w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300 border-white"
                      : "placeholder:text-gray-500 border-black"
                  }`}
                  value={ticketData.started_at}
                  onChange={(e) => {
                    setTicketData({
                      ...ticketData,
                      started_at: e.target.value,
                    });
                  }}
                  placeholder="Enter start date"
                />
              </div>
              <div className="flex flex-col gap-1 relative">
                <label htmlFor="status">Status</label>
                <div
                  className={`${
                    theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                  } border rounded-md p-2`}
                  onClick={() => setStatusOptionDropdown(!statusOptionDropdown)}
                >
                  {statusOptionValue}
                </div>
                {statusOptionDropdown && (
                  <div
                    className={`absolute top-full left-0 mt-2 w-full shadow-lg rounded-md z-10 ${
                      theme === "dark"
                        ? "bg-dashboardDarkMode text-white"
                        : "bg-lightMode text-black"
                    }`}
                  >
                    {statusOptions.map((option) => (
                      <div
                        key={option}
                        className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                          theme === "dark" ? "hover:bg-gray-700" : ""
                        }`}
                        onClick={() => {
                          setStatusOptionValue(option);
                          setStatusOptionDropdown(false);
                        }}
                      >
                        {option.icon}
                        <span className="capitalize">{option}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1 relative">
                <label htmlFor="status">Priority</label>
                <div
                  className={`${
                    theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                  } border rounded-md p-2`}
                  onClick={() => setPiortyDropdown(!piortyDropdown)}
                >
                  {priorityValue}
                </div>
                {piortyDropdown && (
                  <div
                    className={`absolute top-full left-0 mt-2 w-full shadow-lg rounded-md z-10 ${
                      theme === "dark"
                        ? "bg-dashboardDarkMode text-white"
                        : "bg-lightMode text-black"
                    }`}
                  >
                    {piortyOptions.map((option) => (
                      <div
                        key={option}
                        className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                          theme === "dark" ? "hover:bg-gray-700" : ""
                        }`}
                        onClick={() => {
                          setPriorityValue(option);
                          setPiortyDropdown(false);
                        }}
                      >
                        {option.icon}
                        <span className="capitalize">{option}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1 relative">
                <label htmlFor="status">Select project</label>
                <div
                  className={`${
                    theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                  } border rounded-md p-2`}
                  onClick={() => setProjectDropdown(!projectsDropdown)}
                >
                  {projects.find((project) => project.id === selectedProject)
                    ? projects.find((project) => project.id === selectedProject)
                        .title
                    : "Select project"}
                </div>
                {projectsDropdown && (
                  <div
                    className={`absolute top-full left-0 mt-2 w-full shadow-lg rounded-md z-10 ${
                      theme === "dark"
                        ? "bg-dashboardDarkMode text-white"
                        : "bg-lightMode text-black"
                    }`}
                  >
                    {projects.map((option) => (
                      <div
                        key={option.id}
                        className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                          theme === "dark" ? "hover:bg-gray-700" : ""
                        }`}
                        onClick={() => {
                          setSelectedProject(option.id);
                          setProjectDropdown(false);
                        }}
                      >
                        {option.icon}
                        <span className="capitalize">{option.title}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1 relative">
                <label htmlFor="status">Select user</label>
                <div
                  className={`${
                    theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                  } border rounded-md p-2`}
                  onClick={() => setUserDropDown(!userDropDown)}
                >
                  {users?.find((user) => user.id === selectedUser)
                    ?.first_name || "Select User"}{" "}
                  {users?.find((user) => user.id === selectedUser)?.last_name ||
                    ""}
                </div>
                {userDropDown && (
                  <div
                    className={`absolute top-full left-0 h-60 overflow-scroll mt-2 w-full shadow-lg rounded-md z-10 ${
                      theme === "dark"
                        ? "bg-dashboardDarkMode text-white"
                        : "bg-lightMode text-black"
                    }`}
                  >
                    {users?.map((option) => (
                      <div
                        key={option.id}
                        className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                          theme === "dark" ? "hover:bg-gray-700" : ""
                        }`}
                        onClick={() => {
                          setSelectedUser(option.id);
                          setUserDropDown(false);
                        }}
                      >
                        {option.icon}
                        <span className="capitalize">
                          {option.first_name} {option.last_name}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-1 relative">
                <label htmlFor="status">Milestone</label>
                <div
                  className={`${
                    theme === "dark"
                      ? "border-white text-white"
                      : "border-black text-black"
                  } border rounded-md p-2`}
                  onClick={() => setMilestoneDropDown(!milestoneDropDown)}
                >
                  {milestones?.find(
                    (milestone) => milestone.id === selctedMilestone
                  )?.title || "Select Milestone"}
                </div>
                {milestoneDropDown && (
                  <div
                    className={`absolute top-full left-0 mt-2 w-full shadow-lg rounded-md z-10 ${
                      theme === "dark"
                        ? "bg-dashboardDarkMode text-white"
                        : "bg-lightMode text-black"
                    }`}
                  >
                    {milestones
                      ?.filter((item) => item.project?.id === selectedProject) // Use filter instead of find to get an array
                      ?.map((option) => (
                        <div
                          key={option.id} // Use a unique identifier like option.id
                          className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                            theme === "dark" ? "hover:bg-gray-700" : ""
                          }`}
                          onClick={() => {
                            setSelectedMilestone(option.id);
                            setMilestoneDropDown(false);
                          }}
                        >
                          {option.icon}
                          <span className="capitalize">{option.title}</span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </form>
            <hr
              className={`border-t ${
                theme === "dark" ? "border-white" : "border-black"
              }`}
            />
            <div className="flex gap-2 justify-end pt-4">
              <button
                className={` border rounded-3xl px-3 py-2 bg-transparent  ${
                  theme === "dark" ? "border-gray-700" : "border-gray-500"
                }`}
                onClick={() => {
                  setTicketModal(false);
                }}
              >
                Cancel
              </button>
              <button className="heroButton" onClick={handleSubmit}>
                Create
              </button>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default TicketModal;

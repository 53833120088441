import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toggleAuth, setRememberMe, setStoredCredentials } from "../../../Redux/Slices/auth-slice";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";
import BackBtn from "../../backBtn/BackBtn";
import ParticlesBackground from "../../home/ParticalsBackground";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import AnimationLottie from "../../../lottie/animation-lottie";
import loginAnimation from "../../../lottie/loginAnimation.json";

const Login = () => {
  const { theme } = useSelector((state) => state.theme);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { isLogin, userInfo, rememberMe } = useSelector((state) => state?.auth || {});
 
  const [showPassword, setShowPassword] = useState(false);

  const getSubdomain = () => {
    const host = window.location.host
    if (host.includes("localhost")) {
      return host.split(".localhost")[0];
    }else if(host.includes(".bugtrackin")){
      return host.split(".bugtrackin.com")[0];
    }
    return null;
  };

  const subdomain = getSubdomain();
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
    domain: subdomain,
  });

  useEffect(() => {
    // Load stored credentials if rememberMe is true
    const storedCredentials = JSON.parse(localStorage.getItem("userCredentials"));
    if (storedCredentials && rememberMe) {
      setFormValues({
        email: storedCredentials.email,
        password: storedCredentials.password,
        domain: subdomain,
      });
      dispatch(setStoredCredentials({ userInfo: storedCredentials }));
    }
  }, [dispatch, rememberMe, subdomain]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!formValues?.email || !formValues?.password) {
      toast.dismiss();
      toast.error("All fields are required!");
      return;
    }
    setLoading(true);
    try {
      const result = await fetchApi.post("login/", formValues);
      if (result.status === 200) {
        dispatch(toggleAuth({ isLogin: true, userInfo: result.data }));
        if (rememberMe) {
          localStorage.setItem("userCredentials", JSON.stringify(formValues));
        } else {
          localStorage.removeItem("userCredentials");
        }
        navigate("/dashboard");
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      toast.dismiss();
      toast.error(error?.response?.data?.error || "Login failed");
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleRememberMe = (e) => {
    dispatch(setRememberMe(e.target.checked));
  };

  return (
    <>
      <style>{`
        .login-box {
          background-color:${theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"};
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .input-field {
          background-color:${theme === "dark" ? "#444" : "#ffffff"};
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
      `}</style>
      <ParticlesBackground id="particaljs" />
      <div className="login-container">
        <BackBtn bgColor={"red"} />
        <div className="md:flex justify-center md:w-1/2 hidden">
          <AnimationLottie animationPath={loginAnimation} width={"70%"} />
        </div>
        <div className="flex justify-center w-full md:w-1/2">
          <div className="login-box">
            <h2 className="main-heading text-center">Login</h2>
            <form onSubmit={handleLogin}>
              <div className="input-group">
                <label className={`input-label ${theme === "dark" ? "text-gray-400" : "text-black"}`}>
                  Enter your email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className={`input-label ${theme === "dark" ? "text-gray-400" : "text-black"}`}>
                  Enter your password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formValues.password}
                    onChange={handleChange}
                    placeholder="Enter your password"
                    className="input-field"
                    style={{ paddingRight: "35px" }}
                  />
                  <div
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </div>
              </div>
              <div className="input-group flex items-center">
                <input
                  type="checkbox"
                  id="rememberMe"
                  checked={rememberMe}
                  onChange={handleRememberMe}
                />
                <label htmlFor="rememberMe" className="ml-2">
                  Remember Me
                </label>
              </div>
              <button className="heroButton w-full" type="submit" disabled={loading}>
                {loading ? "Loading..." : "Login"}
              </button>
            </form>
            <div className="forgot-password">
              <a href="/subscriptionplan" className="forgot-link">
                Do not have any account?
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

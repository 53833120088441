import React, { useState } from "react";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css"; 
import { fetchApi } from '../../../utlis/axios';
import { useDispatch, useSelector } from "react-redux";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";


const ContactUsViaMail = (props) => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {    
    e.preventDefault();
    if (!formData.name || !formData.email || !formData.message  || !formData.phone) {
      toast.dismiss()
      return toast.error("All fields are required");
    }
setIsLoading(true)
    try {
     
      const response = await fetchApi.post('contact/', formData, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      
      if (response.status===200) {
        toast.dismiss()
        toast.success(response.data.message);
        setFormData({
          name: "",
          email: "",
          phone:"",
          message: "",
        });
      } else {
        toast.dismiss()
        toast.error("Failed to send message. Please try again later.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      toast.dismiss()
      toast.error("Something went wrong. Please try again later.");
    }
    setIsLoading(false)
  };


  return (
    <>
    
    <style>{`
        .input,
  .textarea {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color:${theme === 'dark' ? '#333333' : '#ffffff'} ;
    transform: scale(1.05);
    color:${theme === 'dark' ? '#ffffff' : '#000000'};
    border: none;
  }
  .textarea {
    height: 8rem;
    resize: none;
  }
  
    `}</style>
  
      <div className={styles.content}>
        <div className={styles.formContainer}>
          <form className={`${styles.form} `} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="name" className={styles.label}>
                Full Name
              </label>
              <input
                id="name"
                placeholder="Your full name"
                className="input"
                style={{border:"1px solid red"}}
                value={formData.name}
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>
                Email Address
              </label>
              <input
                id="email"
                type="email"
                placeholder="Your email address"
                className="input"
                value={formData.email}
                onChange={handleInputChange}
                style={{border:"1px solid red"}}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="phone" className={styles.label}>
                Phone number
              </label>
              <input
                id="phone"
                type="number"
                placeholder="Your phone number"
                className="input"
                value={formData.phone}
                onChange={handleInputChange}
                style={{border:"1px solid red"}}
                maxlength="20"
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="message" className={styles.label}>
                Your Message
              </label>
              <textarea
                id="message"
                placeholder="Write your message"
                className="textarea"
                value={formData.message}
                onChange={handleInputChange}
                style={{border:"1px solid red"}}
              />
            </div>
           
            <button type="submit" className="heroButton">
            {isLoading?"Sending...":"Send"}
            </button>
          </form>
        </div>
     
      </div>
   
   
    </>
  );
};

export default ContactUsViaMail;

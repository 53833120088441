import React from 'react';
import { MdSecurity } from 'react-icons/md';
import { AiOutlineBarChart, AiOutlineAlert, AiOutlineTool } from 'react-icons/ai';
import workimg from '../../../assets/howitwork.png';
import styles from './styles.module.css'; 
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";

const HowItWorks = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <div className={`${styles.howItWorks} sm:mt-10`}>
      <div className="container">
        <div className={styles.intro}>
          <div className={styles.introText}>
            <h1 className="main-heading">How It Works</h1>
            <p className={styles.description}>Discover how our app can transform your workflow.</p>
           <Link to='/subscriptionplan'> <button className="heroButton">Get Started Now</button></Link>
          </div>
          <div className={styles.introImage}>
            <img src={workimg} alt="How it works illustration" className={styles.image} />
          </div>
        </div>
        <div className={`${styles.steps} `}>
          {steps.map((step, index) => ( 
            <div key={index} className={styles.step}>
              <step.icon className={styles.workIcon} />
              <h2 className="sub-heading">{step.title}</h2>
              <p className={styles.stepDescription}>{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const steps = [
  {
    icon: MdSecurity,
    title: 'User Authentication',
    description: 'Secure login with role-based access.',
  },
  {
    icon: AiOutlineBarChart,
    title: 'Analytics and Charts',
    description: 'Visualize your data with comprehensive charts.',
  },
  {
    icon: AiOutlineAlert,
    title: 'Customizable Alerts',
    description: 'Set up personalized notifications.',
  },
  { 
    icon: AiOutlineTool,
    title: 'Seamless Integrations',
    description: 'Connect with your favorite tools effortlessly.',
  },
];

export default HowItWorks;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowForward } from "react-icons/io";
import "react-datepicker/dist/react-datepicker.css";
import { IoAdd, IoClose } from "react-icons/io5";
import { Link } from "react-router-dom";
import { FaTasks, FaCheckCircle, FaPause } from "react-icons/fa";
import { FaCalendarAlt } from "react-icons/fa";
import { GoProject } from "react-icons/go";
import { toast } from "react-toastify";
import { fetchApi } from "../../../utlis/axios";
import DropZone from "./DropZone";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Loader from "../../Loader/Loader";
import { FcCancel } from "react-icons/fc";
import { updateProject } from "../../../Redux/Slices/project-slice";
import TicketModal from "./TicketModal";
import { FaHourglassHalf, FaBan, FaClipboardList } from "react-icons/fa";
import { MdOutlineDoneAll } from "react-icons/md";

const Board = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isModalOpen, setShowModal] = useState(false);
  const [ticketModal, setTicketModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState("completed");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [creating, setCreating] = useState(false);
  const [projectData, setProjectData] = useState({
    title: "",
    description: "",
    startDate: new Date(),
    endDate: "",
    url: "",
    budget: "",
  });
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [tickets, setTickets] = useState([]);
  const dispatch = useDispatch();


  const options = [
    { label: "canceled", icon: <FcCancel className="w-4 h-4 mr-2" /> },
    { label: "paused", icon: <FaPause className="w-4 h-4 mr-2" /> },
    { label: "in-progress", icon: <FaTasks className="w-4 h-4 mr-2" /> },
    { label: "completed", icon: <FaCheckCircle className="w-4 h-4 mr-2" /> },
  ];

  const handleSubmit = async (e) => {
    if (
      !projectData.title ||
      !projectData.description ||
      !projectData.url ||
      !projectData.budget ||
      !projectData.startDate ||
      !projectData.endDate
    ) {
      toast.error("Please fill all the fields");
      return;
    }
    setCreating(true);
    try {
      const formdata = new FormData();
      formdata.append("title", projectData.title);
      formdata.append("description", projectData.description);
      formdata.append("start_date", projectData.startDate);
      formdata.append("end_date", projectData.endDate);
      formdata.append("url", projectData.url);
      formdata.append("budget", projectData.budget);
      formdata.append("status", selectedOption);
      formdata.append("company", userInfo?.company?.id);
      const res = await fetchApi.post("/projects/", formdata, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 201) {
        setShowModal(false);
        toast.dismiss();
        toast.success("Project created successfully");
        setProjectData({
          title: "",
          description: "",
          startDate: new Date(),
          endDate: "",
          url: "",
          budget: "",
        });
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setCreating(false);
    }
  };

  const handleCreate = (status) => {
    setTicketModal(true)
  };

  const moveCard = (id, newStatus) => {
    setProjects((prev) =>
      prev.map((project) =>
        project.id === id ? { ...project, status: newStatus } : project
      )
    );
  };

  const statuses = [ 'todo', 'done', 'in-review', 'rejected', 'in-progress' ]

  const getProjects = async () => {
    setLoading(true);
    try {
      const res = await fetchApi.get("projects/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setProjects(res.data.results);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const getTickets = async () => {
    setLoading(true);
    try {
      const res = await fetchApi.get("project-tickets/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
       setTickets(res.data.results)
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(()=>{
    getProjects()
    getTickets()
  }, []);

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="w-full">
        {loading ? (
          <Loader style={{ height: "100%" }} />
        ) : (
          <div className="w-full flex gap-2">
            <div className="w-1/4 mt-4 px-2">
              <button
                className={`py-2 mb-2 w-full border box-border overflow-hidden flex items-center justify-center gap-2 rounded-md shadow-md cursor-pointer ${
                  theme === "dark"
                    ? "text-white border-gray-700"
                    : "text-black border-gray-400"
                }`}
                onClick={() => setShowModal(true)}
              >
                <IoAdd className="text-white" />
                <span>Create project</span>
              </button>
              {projects.map((project) => (
                <div
                  className={`p-4 mb-2 rounded-md shadow-md cursor-pointer ${
                    theme === "dark"
                      ? "text-white bg-darkMode"
                      : "text-black bg-slate-50"
                  }`}
                >
                  <div className="flex gap-2 items-center">
                    <GoProject />
                    <span className="text-base font-normal">
                      {project.title}
                    </span>
                  </div>
                  <div className="flex w-full justify-between items-center">
                    <div className="flex gap-2 mt-2 items-center">
                      <FaCalendarAlt />
                      <span className="text-xs ">{project.start_date}</span>
                    </div>
                    <Link
                      to={`/dashboard/project/${project.id}`}
                      className="cursor-pointer"
                      onClick={() => dispatch(updateProject({ project }))}
                    >
                      Detail
                    </Link>
                  </div>
                </div>
              ))}
            </div>
            <div
              className="cards_container overflow-x-scroll flex gap-4 w-3/4 mt-8"
              style={{ minWidth: "75%" }}
            >
              {statuses.map((status) => (
                <DropZone
                  key={status}
                  status={status}
                  tickets={tickets.filter(
                    (project) => project.status === status
                  )}
                  moveCard={moveCard}
                  theme={theme}
                  handleCreate={handleCreate}
                />
              ))}
            </div>
          </div>
        )}
        {isModalOpen && (
          <>
            {/* Overlay */}
            <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>

            {/* Modal Content */}
            <div className="fixed inset-0 flex items-center justify-center z-20 ">
              <div
                className={` p-6 rounded-lg shadow-lg max-w-screen-md w-full ${
                  theme === "dark"
                    ? "bg-darkMode text-white"
                    : "bg-lightMode text-black"
                }`}
              >
                <div className="flex justify-between">
                  <div className="flex gap-2 items-center">
                    <IoIosArrowForward className="w-4 h-4" />
                    <h2 className="text-base font-semibold">New Project</h2>
                  </div>
                  <IoClose
                    className="w-6 h-6"
                    onClick={() => {
                      setShowModal(false);
                      setProjectData({
                        title: "",
                        budget: "",
                        url: "",
                        description: "",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  />
                </div>
                <input
                  type="text"
                  className={`text-2xl font-normal border-none bg-transparent outline-none mt-6 mb-2 w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300"
                      : "placeholder:text-gray-500"
                  }`}
                  value={projectData.title}
                  onChange={(e) =>
                    setProjectData({ ...projectData, title: e.target.value })
                  }
                  placeholder="Enter title of project"
                />
                <input
                  type="text"
                  className={`text-base font-normal border-none bg-transparent outline-none mt-2 mb-2 w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300"
                      : "placeholder:text-gray-500"
                  }`}
                  value={projectData.budget}
                  onChange={(e) =>
                    setProjectData({ ...projectData, budget: e.target.value })
                  }
                  placeholder="Budget of project"
                />
                <input
                  type="text"
                  className={`text-base font-normal border-none bg-transparent outline-none mt-2 mb-2 w-full ${
                    theme === "dark"
                      ? "placeholder:text-gray-300"
                      : "placeholder:text-gray-500"
                  }`}
                  value={projectData.url}
                  onChange={(e) =>
                    setProjectData({ ...projectData, url: e.target.value })
                  }
                  placeholder="Enter Url of project"
                />
                <div className="flex relative my-2 gap-2">
                  <div
                    className={`flex items-center justify-between border rounded-md px-2 py-1 cursor-pointer ${
                      theme === "dark" ? "border-gray-700" : "border-gray-500"
                    }`}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    <div className="flex items-center">
                      {
                        options.find(
                          (option) => option.label === selectedOption
                        )?.icon
                      }
                      <span>{selectedOption}</span>
                    </div>
                  </div>
                  {isDropdownOpen && (
                    <div
                      className={`absolute top-full left-0 mt-2 w-44 shadow-lg rounded-md z-10 ${
                        theme === "dark"
                          ? "bg-dashboardDarkMode text-white"
                          : "bg-lightMode text-black"
                      }`}
                    >
                      {options.map((option) => (
                        <div
                          key={option.label}
                          className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                            theme === "dark" ? "hover:bg-gray-700" : ""
                          }`}
                          onClick={() => {
                            setSelectedOption(option.label);
                            setIsDropdownOpen(false);
                          }}
                        >
                          {option.icon}
                          <span className="capitalize">{option.label}</span>
                        </div>
                      ))}
                    </div>
                  )}
                  <input
                    type="date"
                    name=""
                    id=""
                    placeholder="Start date"
                    value={projectData.startDate}
                    onChange={(e) =>
                      setProjectData({
                        ...projectData,
                        startDate: e.target.value,
                      })
                    }
                    className={`bg-transparent outline-none border px-1 rounded-md ${
                      theme === "dark" ? "border-gray-700" : "border-gray-500"
                    }`}
                  />
                  <input
                    type="date"
                    name=""
                    id=""
                    value={projectData.endDate}
                    onChange={(e) =>
                      setProjectData({
                        ...projectData,
                        endDate: e.target.value,
                      })
                    }
                    placeholder="End date"
                    className={`bg-transparent outline-none border px-1 rounded-md ${
                      theme === "dark" ? "border-gray-700" : "border-gray-500"
                    }`}
                  />
                </div>
                <hr
                  className={`border-t ${
                    theme === "dark" ? "border-white" : "border-black"
                  }`}
                />
                <textarea
                  rows={6}
                  type="text"
                  className={`text-base font-normal border-none bg-transparent outline-none mt-6 mb-2 w-full text-start ${
                    theme === "dark"
                      ? "placeholder:text-gray-300"
                      : "placeholder:text-gray-500"
                  }`}
                  value={projectData.description}
                  onChange={(e) =>
                    setProjectData({
                      ...projectData,
                      description: e.target.value,
                    })
                  }
                  placeholder="Enter brief description of project"
                />
                <hr
                  className={`border-t ${
                    theme === "dark" ? "border-white" : "border-black"
                  }`}
                />
                <div className="flex gap-2 justify-end pt-4">
                  <button
                    className={` border rounded-3xl px-3 py-2 bg-transparent  ${
                      theme === "dark" ? "border-gray-700" : "border-gray-500"
                    }`}
                    onClick={() => {
                      setShowModal(false);
                      setProjectData({
                        title: "",
                        budget: "",
                        url: "",
                        description: "",
                        startDate: "",
                        endDate: "",
                      });
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="heroButton"
                    disabled={creating}
                    onClick={handleSubmit}
                  >
                    Create
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {ticketModal && <TicketModal setTicketModal={setTicketModal} projects={projects}/>}
      </div>
    </DndProvider>
  );
};

export default Board;

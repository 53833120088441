import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import { GoProject } from "react-icons/go";
import { FaCalendarAlt } from "react-icons/fa";
const Card = ({ ticket }) => {
    const {theme}=useSelector((state)=>state.theme)
  const [, drag] = useDrag({
    type: "CARD",
    item: { id: ticket.id },
  });

  return (
    <div
      ref={drag}
      className={`p-4 mb-2 rounded-md shadow-md cursor-pointer ${theme === 'dark' ? 'text-white bg-dashboardDarkMode':'text-black bg-slate-50'}`}
    >
    <div className="flex gap-2 items-center">
        <GoProject/>
        <span className="text-base font-normal">{ticket?.title}</span>
    </div>
    <div className="flex gap-2 mt-2 items-center">
        <FaCalendarAlt/>
        <span className="text-xs ">{ticket?.started_at}</span>
    </div>
    </div>
  );
};

export default Card
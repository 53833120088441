import React, { useEffect, useState } from "react";
import { MdElectricBolt, MdMenu, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { toggleTheme } from "../../Redux/Slices/theme-slice";
import { toggleAuth } from "../../Redux/Slices/auth-slice";
import logo from "../../assets/bugtrack.png";

const Navbar = () => {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLogin } = useSelector((state) => state?.auth || "");
  const { theme } = useSelector((state) => state.theme);

  const handleToggleTheme = () => {
    const newTheme = theme === "dark" ? "light" : "dark";
    dispatch(toggleTheme({ theme: newTheme }));
  };

  const toggleNavVisibility = () => {
    setIsNavVisible(!isNavVisible);
  };

  const handleLogout = () => {
    dispatch(toggleAuth({ isLogin: false, userInfo: null }));
    navigate("/");
  };

  return (
    <header
      className={`sticky-navbar ${
        theme === "dark" ? "dark_mode" : "light_mode"
      }`}
    >
      <div className="header-top">
        <div className="logo">
          <img src={logo} className="h-6 w-6 mb-1" />
          <span>
            <span className="text-[#FF0000] ">ug</span>Trackin
          </span>
        </div>
        <button className="nav-toggle-btn" onClick={toggleNavVisibility}>
          {isNavVisible ? <MdClose /> : <MdMenu />}
        </button>
      </div>
      <nav
        className={`${isNavVisible ? "visible" : ""} ${
          isNavVisible ? (theme === "dark" ? "navdark" : "navlight") : ""
        } text-sm`}
      >
        <NavLink to="/dashboard">Dashboard</NavLink>
        <a href="#features">Features</a>
        <a href="#contactUs">Contact Us</a>
        <a href="#pricingPlans">Pricing Plans</a>
        <NavLink to="">Blog</NavLink>
        <NavLink to="">Help Center</NavLink>
        <button
          className={`p-3 text-sm rounded-full transition duration-500 text-white ${
            theme !== "dark" ? "bg-black " : "bg-custom-gradient "
          }`}
          onClick={handleToggleTheme}
        >
          {theme !== "dark" ? "🌙 Dark Mode" : "☀️ Light Mode"}
        </button>
        {isLogin ? (
          <div className="heroButton cursor-pointer" onClick={handleLogout}>
            Log Out
          </div>
        ) : (
          <NavLink to="/login" className="heroButton">
            Sign In
          </NavLink>
        )}
        {isLogin ? (
          <></>
        ) : (
          <NavLink to="/subscriptionplan" className="heroButton">
            Get Started
          </NavLink>
        )}
      </nav>
    </header>
  );
};

export default Navbar;

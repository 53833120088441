import React, { useState } from "react";
import AnimationLottie from "../../../lottie/animation-lottie";
import workingAnimation from "../../../lottie/workingAnimation.json";
import styles from "./styles.module.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const HeroSection = () => {
  const { isLogin, userInfo } = useSelector((state) => state?.auth || {});
  const getSubdomain = () => {
    const host = window.location.host;
    if (host.includes("localhost")) {
      return host.split(".localhost")[0];
    } else if (host.includes(".bugtrackin")) {
      return host.split(".bugtrackin.com")[0];
    }
    return null;
  };

  const subdomain = getSubdomain();
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.heroText}>
          <h2 className="main-heading">Maximize Your Efficiency</h2>
          <p>
            Track time, manage tasks, and boost productivity with our powerful
            desktop app.
          </p>
          <Link
            to={`${
              isLogin && subdomain === userInfo?.user?.domain
                ? "/dashboard/summary"
                : "/login"
            }`}
            className="heroButton"
          >
            Start Logging Time
          </Link>
        </div>
        <div className={styles.heroAnimation}>
          <AnimationLottie animationPath={workingAnimation} />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FaTasks, FaCheckCircle, FaPause } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { MdOutlineFlag } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { IoAdd, IoClose } from "react-icons/io5";
import { toast } from "react-toastify";
import { fetchApi } from "../../../utlis/axios";

const ProjectDetail = ({}) => {
  const { theme } = useSelector((state) => state.theme);
  const project = useSelector((state) => state.projects.project);
  const { id } = useParams();
  const { userInfo } = useSelector((state) => state.auth);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("completed");
  const [milestones, setMilestones] = useState([]);
  const options = [
    { label: "canceled", icon: <FcCancel className="w-4 h-4 mr-2" /> },
    { label: "paused", icon: <FaPause className="w-4 h-4 mr-2" /> },
    { label: "in-progress", icon: <FaTasks className="w-4 h-4 mr-2" /> },
    { label: "completed", icon: <FaCheckCircle className="w-4 h-4 mr-2" /> },
  ];
  const [projectData, setProjectData] = useState({
    title: project.title,
    description: project.description,
    startDate: project.start_date,
    endDate: project.end_date,
    url: project.url,
    budget: project.budget,
  });

  const fetchProject = async () => {
    try {
      const res = await fetchApi.get(`projects/${id}/`, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });
      if (res.status === 200) {
        // Handle response
      }
    } catch (error) {
      toast?.dismiss();
      toast?.error(error.message);
    }
  };

  const getMilestones = async () => {
    try {
      const res = await fetchApi.get(`/project-milestones/`, {
        headers: { Authorization: `Bearer ${userInfo.access_token}` },
      });
      if (res.status === 200) {
        setMilestones(res.data.results);
      }
    } catch (error) {
      toast?.error(error.message);
    }
  };

  useEffect(() => {
    getMilestones();
    fetchProject();
  }, []);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [selectedOption1, setSelectedOption1] = useState("completed");
  const [isModalOpen, setShowModal] = useState(false);
  const [milstoneData, setMileStoneData] = useState({
    title: "",
    amount: "",
    description: "",
    status: selectedOption1,
    project: project.id,
  });

  const handleSubmit = async () => {
  
    try {
      const mdata = new FormData();
      mdata.append("title", milstoneData.title);
      mdata.append("amount", milstoneData.amount);
      mdata.append("description", milstoneData.description);
      mdata.append("status", milstoneData.status);
      mdata.append("project", milstoneData.project);
      const res = await fetchApi.post("/project-milestones/", mdata, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`, // Only include Authorization
        },
      });
  
      if (res.status === 201) {
        toast?.dismiss();
        toast?.success("Milestone created successfully");
        setMileStoneData({
          title: "",
          amount: "",
          description: "",
          status: selectedOption1,
          project: project.id,
        });
        setShowModal(false)
      }
    } catch (error) {
      toast?.dismiss();
      toast?.error(error.message);
    }
  };
  
  const getMilestone = async () => {
    try {
      const res = await fetchApi.get(`/project-milestones/`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (res.status === 200) {
        setMilestones(res.data.results);
      }
    } catch (error) {
      toast?.error(error.message);
    }
  };
  useEffect(()=>{
    getMilestone()
    fetchProject()
  },[])

  return (
    <div className={`mt-8 max-w-3xl mx-auto p-6  rounded-lg shadow-md ${theme === 'dark' ? 'bg-darkMode' : 'bg-lightMode'}`}>
      <div className={`text-gray-800 dark:text-gray-200`}>
        <input
          type="text"
          className={`w-full text-2xl font-semibold bg-transparent outline-none placeholder-gray-400 dark:placeholder-gray-500`}
          value={projectData.title}
          onChange={(e) =>
            setProjectData({ ...projectData, title: e.target.value })
          }
          placeholder="Enter project title"
        />
        <div className="flex gap-4 my-4">
          <input
            type="text"
            className={`w-1/2 text-base bg-transparent outline-none placeholder-gray-400 dark:placeholder-gray-500 border border-gray-300 dark:border-gray-700 rounded-md p-2`}
            value={projectData.budget}
            onChange={(e) =>
              setProjectData({ ...projectData, budget: e.target.value })
            }
            placeholder="Budget"
          />
          <input
            type="text"
            className={`w-1/2 text-base bg-transparent outline-none placeholder-gray-400 dark:placeholder-gray-500 border border-gray-300 dark:border-gray-700 rounded-md p-2`}
            value={projectData.url}
            onChange={(e) =>
              setProjectData({ ...projectData, url: e.target.value })
            }
            placeholder="Project URL"
          />
        </div>
        <div className="flex items-center gap-4 my-4">
          <div
            className={`relative w-1/3 border border-gray-300 dark:border-gray-700 rounded-md p-2 cursor-pointer`}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <div className="flex items-center">
              {options.find((option) => option.label === selectedOption)?.icon}
              <span className="ml-2 capitalize">{selectedOption}</span>
            </div>
            {isDropdownOpen && (
              <div
                className={`absolute top-full left-0 mt-2 w-full bg-white dark:bg-gray-800 shadow-lg rounded-md z-10`}
              >
                {options.map((option) => (
                  <div
                    key={option.label}
                    className={`flex items-center px-3 py-2 hover:bg-gray-200 dark:hover:bg-gray-700 cursor-pointer`}
                    onClick={() => {
                      setSelectedOption(option.label);
                      setIsDropdownOpen(false);
                    }}
                  >
                    {option.icon}
                    <span className="capitalize ml-2">{option.label}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
          <input
            type="date"
            className={`w-1/3 text-base bg-transparent outline-none placeholder-gray-400 dark:placeholder-gray-500 border border-gray-300 dark:border-gray-700 rounded-md p-2`}
            value={projectData.startDate}
            onChange={(e) =>
              setProjectData({ ...projectData, startDate: e.target.value })
            }
          />
          <input
            type="date"
            className={`w-1/3 text-base bg-transparent outline-none placeholder-gray-400 dark:placeholder-gray-500 border border-gray-300 dark:border-gray-700 rounded-md p-2`}
            value={projectData.endDate}
            onChange={(e) =>
              setProjectData({ ...projectData, endDate: e.target.value })
            }
          />
        </div>
        <textarea
          rows={6}
          className={`w-full text-base bg-transparent outline-none placeholder-gray-400 dark:placeholder-gray-500 border border-gray-300 dark:border-gray-700 rounded-md p-2`}
          value={projectData.description}
          onChange={(e) =>
            setProjectData({ ...projectData, description: e.target.value })
          }
          placeholder="Enter project description"
        />
        <div className="my-6">
          <h3 className="text-lg font-semibold mb-4">Milestones</h3>
          {milestones.map((milestone) => (
            <div
              key={milestone.id}
              className="flex items-center gap-2 p-4 bg-gray-100 dark:bg-gray-800 rounded-md mb-2"
            >
              <MdOutlineFlag className="w-5 h-5 text-gray-600 dark:text-gray-400" />
              <span>{milestone.title}</span>
            </div>
          ))}
        </div>
        <button
          className="flex items-center gap-2 bg-blue-500 text-white px-4 py-2 rounded-md shadow hover:bg-blue-600 transition"
          onClick={() => setShowModal(true)}
        >
          <IoAdd />
          Add Milestone
        </button>

        {isModalOpen && <>

          <div className="fixed inset-0 bg-black bg-opacity-50 z-10"></div>

      
          <div className="fixed inset-0 flex items-center justify-center z-20 ">
            <div
              className={` p-6 rounded-lg shadow-lg max-w-screen-sm w-full ${
                theme === "dark"
                  ? "bg-darkMode text-white"
                  : "bg-lightMode text-black"
              }`}
            >
              <div className="flex justify-between">
                <div className="flex gap-2 items-center">
                  <IoIosArrowForward className="w-4 h-4" />
                  <h2 className="text-base font-semibold">New Milestone</h2>
                </div>
                <IoClose
                  className="w-6 h-6"
                  onClick={() => {
                    setShowModal(false);
                    setProjectData({
                      title: "",
                      budget: "",
                      url: "",
                      description: "",
                      startDate: "",
                      endDate: "",
                    });
                  }}
                />
              </div>
              <div className="flex w-full gap-4 mt-4">
                <div className="flex flex-col gap-1 w-1/2">
                  <label htmlFor="">Title</label>
                  <input
                    type="text"
                    className={`text-base border w-full p-2 font-normal rounded-md bg-transparent outline-none ${
                      theme === "dark"
                        ? "placeholder:text-gray-300 border-white"
                        : "placeholder:text-gray-500 border-black"
                    }`}
                    value={milstoneData.title}
                    onChange={(e) =>
                      setMileStoneData({
                        ...milstoneData,
                        title: e.target.value,
                      })
                    }
                    placeholder="Enter title of project"
                  />
                </div>
                <div className="flex flex-col gap-1 w-1/2">
                  <label htmlFor="">Amount</label>
                  <input
                    type="text"
                    className={`text-base font-normal border bg-transparent outline-none w-full rounded-md p-2 ${
                      theme === "dark"
                        ? "placeholder:text-gray-300 border-white"
                        : "placeholder:text-gray-500 border-black"
                    }`}
                    value={milstoneData.amount}
                    onChange={(e) =>
                      setMileStoneData({
                        ...milstoneData,
                        amount: e.target.value,
                      })
                    }
                    placeholder="Amount"
                  />
                </div>
              </div>
              <div className="flex relative mt-4 gap-4 w-full">
                <div className="flex flex-col gap-1 w-1/2">
                  <label htmlFor="">Status*</label>
                  <div
                    className={`flex w-full items-center justify-between border rounded-md px-2 py-2 cursor-pointer ${
                      theme === "dark" ? "border-white" : "border-black"
                    }`}
                    onClick={() => setIsDropdownOpen1(!isDropdownOpen1)}
                  >
                    <div className="flex items-center">
                      {
                        options.find(
                          (option) => option.label === selectedOption1
                        )?.icon
                      }
                      <span>{selectedOption1}</span>
                    </div>
                  </div>
                </div>

                {isDropdownOpen1 && (
                  <div
                    className={`absolute top-full left-0 mt-2 w-44 shadow-lg rounded-md z-10 ${
                      theme === "dark"
                        ? "bg-dashboardDarkMode text-white"
                        : "bg-lightMode text-black"
                    }`}
                  >
                    {options.map((option) => (
                      <div
                        key={option.label}
                        className={`flex items-center px-3 py-2 hover:bg-gray-200 cursor-pointer ${
                          theme === "dark" ? "hover:bg-gray-700" : ""
                        }`}
                        onClick={() => {
                          setSelectedOption1(option.label);
                          setIsDropdownOpen1(false);
                        }}
                      >
                        {option.icon}
                        <span className="capitalize">{option.label}</span>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex flex-col gap-1 w-1/2">
                  <label htmlFor="">Discription*</label>
                  <textarea
                    rows={1}
                    type="text"
                    className={`text-base font-normal border p-2 w-full rounded-md bg-transparent outline-none text-start ${
                      theme === "dark"
                        ? "placeholder:text-gray-300 border-white"
                        : "placeholder:text-gray-500 border-black"
                    }`}
                    value={milstoneData.description}
                    onChange={(e) =>
                      setMileStoneData({
                        ...milstoneData,
                        description: e.target.value,
                      })
                    }
                    placeholder="Enter brief description of project"
                  />
                </div>
              </div>

              <div className="flex gap-2 justify-end pt-4 mt-6">
                <button
                  className={` border rounded-3xl px-3 py-2 bg-transparent  ${
                    theme === "dark" ? "border-gray-700" : "border-gray-500"
                  }`}
                  onClick={() => {
                    setShowModal(false);
                    setProjectData({
                      title: "",
                      budget: "",
                      url: "",
                      description: "",
                      startDate: "",
                      endDate: "",
                    });
                  }}
                >
                  Cancel
                </button>
                <button
                  className="heroButton"
                  // disabled={creating}
                  onClick={handleSubmit}
                >
                  Create
                </button>
              </div>
            </div>
          </div>
        </>
      }
      </div>
     
    </div>
  );
};

export default ProjectDetail;

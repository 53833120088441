import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import styles from "./overtime.module.css";
import Loader from "../../Loader/Loader";
import { FaCheckCircle } from "react-icons/fa";
import { CiCircleRemove, CiSearch } from "react-icons/ci";
import { MdOutlineDeleteOutline } from "react-icons/md";
import { Tooltip} from "@mui/material";

const GetRequest = () => {
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { theme } = useSelector((state) => state?.theme || {});
  const { users } = useSelector((state) => state?.allUsers || {});
  const [requestData, setRequestData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [dataWithoutSearches, setDataWithoutSearches] =useState([])
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [activeTab, setActiveTab] = useState("pending");
  const [searchTerm, setSearchTerm] = useState("");
  const [removing, setRemoving] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [searching, setSearching] = useState(false);
  const [next, setNext] = useState(null);
  const [prev, setPrev] = useState(null);

  // Ref to track whether data has already been fetched
  const isFetchedRef = useRef(false);

  const GetData = async () => {
    setLoading(true);

    try {
      const res = await fetchApi.get("employee-overtimes/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 200) {
        setNext(res.data.next);
        setPrev(res.data.previous);
        setOriginalData(res.data.results);
        setDataWithoutSearches(res.data.results);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };
  const updateData = async () => {
    try {
      const res = await fetchApi.get("employee-overtimes/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 200) {
        setNext(res.data.next);
        setPrev(res.data.previous);
        setOriginalData(res.data.results);
        setDataWithoutSearches(res.data.results);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    }
  };
  const handlePageChange = async (url) => {
    setLoading(true);

    try {
      const res = await fetchApi.get(`${url}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (res.status === 200) {
        setNext(res.data.next);
        setPrev(res.data.previous);
        setOriginalData(res.data.results);
        setDataWithoutSearches(res.data.results);
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isFetchedRef.current) {
      isFetchedRef.current = true; // Mark the API call as initiated
      GetData();
    }
  }, []); // Runs only once when the component mounts

  const handleApprove = async (id) => {
    setUpdating(true);
    try {
      const res = await fetchApi.patch(
        `employee-overtimes/${id}/`,
        { status: "approved" },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );

      if (res.status === 200) {
        updateData(); // Refresh data
        toast.dismiss();
        toast.success("Approved Successfully");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleRemove = async (id) => {
    setRemoving(true);
    try {
      const res = await fetchApi.delete(`employee-overtimes/${id}`, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      updateData(); // Refresh data
      toast.dismiss();
      toast.success("Removed Successfully");
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setRemoving(false);
    }
  };

  const handleReject = async (id) => {
    setRejecting(true);
    try {
      const res = await fetchApi.patch(
        `employee-overtimes/${id}/`,
        { status: "rejected" },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );

      if (res.status === 200) {
        updateData(); // Refresh data
        toast.dismiss();
        toast.success("Rejected Successfully");
      }
    } catch (error) {
      toast.dismiss();
      toast.error(error.message);
    } finally {
      setRejecting(false);
    }
  };

  const getTabStyles = (tabName) => {
    const isActive = activeTab === tabName;
    const activeColor =
      theme === "dark" ? "bg-red-600 text-white" : "bg-red-600 text-white";
    const inactiveColor =
      theme === "dark"
        ? "bg-[#2A2A2E] text-white"
        : "bg-gray-100 text-gray-600";

    return `w-1/3 text-center outline-none py-2 font-bold transition-colors duration-300 ${
      isActive ? activeColor : inactiveColor
    }`;
  };
  useEffect(() => {
    if (activeTab === "approved") {
      setRequestData(originalData.filter((item) => item.status === "approved"));
    } else if (activeTab === "pending") {
      setRequestData(originalData.filter((item) => item.status === "pending"));
    } else if (activeTab === "rejected") {
      setRequestData(originalData.filter((item) => item.status === "rejected"));
    }
  }, [activeTab, originalData]);

  const handleSearch = async () => {
    if (!searchTerm) {
      toast.dismiss();
      toast.error("Please enter a search query");
    } else {
      if (!searching) {
        setSearching(true);
        try {
          const res = await fetchApi.get(
            `employee-overtimes/?search=${searchTerm}`,
            {
              headers: {
                Authorization: `Bearer ${userInfo.access_token}`,
              },
            }
          );

          if (res.status === 200) {
            setRequestData(res.data.results.filter((item) => item.status === "pending"));
            setNext(res.data.next);
            setPrev(res.data.previous);
            setOriginalData(res.data.results);
          }
        } catch (error) {
          toast.dismiss();
          toast.error(error.message);
        } finally {
          setSearching(false);
        }
      } else {
        return;
      }
    }
  };

  return (
    <div className="mt-8">
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div
            className={`flex justify-between items-center mb-4 rounded-3xl ${
              theme === "dark" ? "bg-gray-900" : "bg-gray-200"
            }`}
          >
           <button
              className={`${getTabStyles("pending")} rounded-l-3xl`}
              onClick={() => setActiveTab("pending")}
            >
              Pending
            </button>
            <button
              className={getTabStyles("approved")}
              onClick={() => setActiveTab("approved")}
            >
              Approved
            </button>
            <button
              className={`${getTabStyles("rejected")} rounded-r-3xl`}
              onClick={() => setActiveTab("rejected")}
            
            >
              Rejected
            </button>
           
          </div>
          <div className="mt-2 mb-3">
            <div
              className={`py-2 px-2 flex gap-2 rounded-3xl md:w-1/3 bg-transparent outline-none border  ${
                theme === "dark"
                  ? "border-[#ff0000]"
                  : "border-gray-600 text-black"
              }`}
            >
              <input
                type="text"
                value={searchTerm}
                placeholder="Search here"
                onChange={(e) =>{ setSearchTerm(e.target.value)
                  if(!e.target.value){
                    setOriginalData(dataWithoutSearches)
                    setNext(null)
                    setPrev(null)
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                className="px-1 w-[87%] bg-transparent outline-none"
              />
              <CiSearch
                className="w-6 h-6 cursor-pointer"
                onClick={handleSearch}
              />
            </div>
          </div>
          <div className={`${styles.tableResponsive} mt-2`}>
            <table
              className={`${theme === "dark" ? "text-white" : "text-black"} ${
                styles.table
              }`}
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Status</th>
                  <th>Hours</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              {requestData.length > 0 ? (
                <tbody>
                  {requestData.map((item, i) => (
                    <React.Fragment key={i}>
                      <tr
                        className={`border-red-500 border-b ${
                          theme === "dark"
                            ? "hover:bg-gradient-to-r hover:from-[#fff3] hover:to-[#fff0] transition-all"
                            : "hover:bg-gradient-to-r hover:from-[#6d6d6d33] hover:to-[#fff0] transition-all"
                        }`}
                      >
                        <td>
                          {users.find((user) => user.email === item.user)
                            ?.first_name || "Unknown User"}{" "}
                          {
                            users.find((user) => user.email === item.user)
                              ?.last_name
                          }
                        </td>
                        <td>
                          {item.status === "approved" ? (
                            <span className="bg-green-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                              {item?.status}
                            </span>
                          ) : item.status === "rejected" ? (
                            <span className="bg-red-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                              {item?.status}
                            </span>
                          ) : (
                            <span className="bg-yellow-600 text-white h-[30px] w-[80px] flex rounded-[20px] justify-center items-center">
                              {item?.status}
                            </span>
                          )}
                        </td>
                        <td>{item?.hours}</td>
                        <td>{item?.amount}</td>
                        <td>{item?.type}</td>
                        <td>{item?.description?.slice(0, 30)}</td>
                        {item.status === "pending" ? (
                          <td
                            className="flex justify-center items-center border-b-none"
                            style={{ borderBottom: "none" }}
                          >
                            <Tooltip title='Approve' arrow placement='top'>
                            <button onClick={() => handleApprove(item.id)}>
                              <FaCheckCircle className="w-6 h-6 " />
                            </button>
                            </Tooltip>
                            <Tooltip title='Remove' arrow placement='top'>
                            <button onClick={() => handleReject(item.id)}>
                              <CiCircleRemove className="w-7 h-7 ml-2" />
                            </button>
                            </Tooltip>
                          </td>
                        ) : (
                          <td
                            className="flex justify-center items-center border-b-none"
                            style={{ borderBottom: "none" }}
                          >
                            <Tooltip title='Delete' arrow placement='top'>
                            <button onClick={() => handleRemove(item.id)}>
                              <MdOutlineDeleteOutline className="w-6 h-6" />
                            </button>
                            </Tooltip>
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={6}>No data found</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
          <div className="w-full  mt-2 justify-between flex">
            <span>
              {prev && (
                <button className="heroButton block" onClick={(()=> handlePageChange(prev))}>
                  Previous
                </button>
              )}
            </span>
            <span>
              {next && (
                <button className="heroButton block" onClick={(()=> handlePageChange)}>
                  Next
                </button>
              )}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default GetRequest;

import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ParticlesBackground from "../home/ParticalsBackground";
import GlowCard from "../home/pricingSection/glow-card";
import { fetchApi } from "../../utlis/axios";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { togglePlan } from "../../Redux/Slices/plans-slice";
import { Modal, Popconfirm} from "antd";
import ContactUsViaMail from "../home/contactUs/ContactUsViaMail";
import Calendly from "./Calendly";
import { toggleAuth } from "../../Redux/Slices/auth-slice";
import LoaderGif from '../../assets/loading-gif.gif'

const SubscriptionPage = (props) => {
  const { userInfo } = useSelector((state) => state?.auth || {});
  const plans = useSelector((state) => state.allPlans.plans);
  const isLoading = useSelector((state) => state.allPlans.plansIsLoading);
  const navigate = useNavigate();
 // const [plans, setPlans] = useState([]);

 // const [isLoading, setIsLoading] = useState(false);
  const { theme } = useSelector((state) => state.theme);
  const [changigPlan, setChangingPlan] = useState("");
  const dispatch = useDispatch();
  const [childComponent, setChilComponent] = useState(null);
  const [loader, setLoader] = useState(false);
  const handleCancel = () => {
    setChilComponent(null);
  };

  const onClickSignup = (plan) => {
    if (plan === "enterprise") {
      navigate("/contact-us");
      return;
    }
    dispatch(togglePlan({ plan: plan }));
    navigate("/signup");
  };

  const handlePlans = (plan) => {
    if (!userInfo) {
      dispatch(togglePlan({ plan: plan?.id }));
      navigate("/signup");
    } else {
      if (props.parent === "homes") {
        navigate("/dashboard/changeplan");
      } else {
        setChangingPlan(plan.id);
        onUpgradePlan(plan);
      }
    }
  };
  const onUpgradePlan = async (plan) => {
    setLoader(true);
    let payload = {
      plan: plan.id,
      quantity: plan.number_of_users,
    };
    try {
      const result = await fetchApi.post("upgrade/", payload, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (result.status === 200) {
        toast.success("Updated Successfully!");
        dispatch(togglePlan({ plan: plan }));
        setChangingPlan("");
        let userData = { ...userInfo, user: { ...userInfo.user, plan } };
        dispatch(toggleAuth({ isLogin: true, userInfo: { ...userData } }));

      }
    } catch (error) {
      toast.dismiss();
      toast.error(error?.response?.data?.error || "failed");
      if (error.response.data.detail) {
        toast.error(error.response.data.detail);
      }
      if (error.response && error.response.data.length && error.response.data) {
        error.response.data.forEach((value) => {
          toast.error(value);
        });
      }
      //toast.error(error?.response?.data?.error || "failed");
      setLoader(false);
      setChangingPlan("");
    }
  };

  const isCurrent = (plan) => {
    return plan?.id === userInfo?.user?.plan?.id;
  };
  const features = {
    Basic: [
      "Time tracker",
      "Activity level",
      "Limited screenshots",
      "Limited reports",
      "Idle timeout",
      "Two-day email support",
    ],
    Standard: [
      "Unlimited reports",
      "Project",
      "Milestone",
      "Task",
      "Expenses",
      "One day email support",
      "Company setup",
    ],
    Premium: [
      "Unlimited screenshots",
      "Company template management",
      "Company user invoices",
      "Overtime",
      "Time off and holidays",
      "Timesheet approval",
      "Chat support",
      "Payments & payroll",
      "Admin dashboard",
      "Finance & reporting dashboard",
      "One day email support",
    ],
    Enterprise: [
      "Everything in Premium",
      "Fixed yearly payment",
      "For above 100 users",
      "chat support",
      "one day email support",
    ],
  };

  const getPrice = (str) => {
    const input = str;
    const separator = "per";
    // Using `split`
    const parts = input.split(separator);
    const beforePer = parts[0].trim(); // Text before 'per'
    return "$" + beforePer.replace("$", "");
  };

  const onViaMail = (plan) => {
    setChilComponent(
      <Modal
        width={500}
        title="Contact Us"
        open={true}
        height={50}
        onCancel={handleCancel}
        footer={null}
      >
        <ContactUsViaMail parent="plans" />
      </Modal>
    );
  };

  return (
    <>
      <ParticlesBackground id="particaljs" />
      {childComponent}
      <section
        className={`${styles.pricingSection} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
        id="pricingPlans"
      >
        <div className="plans-container">
          <h2 className={`main-heading text-center py-8 `}>
            Subscription Plans
          </h2>
          {isLoading && plans.length === 0 ? (
            <Loader />
          ) : (
            <div
              className={` lg:gap-5 gap-14 my-7 ${styles.pricingPlans} items-strech`}
            >
              {plans.map((plan, index) => (
                <div className="min-h-[640px] w-[285px]">
                  <GlowCard
                    key={plan.id}
                    identifier={index}
                    plan={plan}
                    page={props.parent}
                    className="h-full"
                  >
                    <div className={`${styles.planDescriptionCard}`}>
                      <h3 className={styles.planTitle}>{plan?.name}</h3>
                      {/* <p className={styles.planDescription}>{plan?.description}</p>  */}
                      {plan.name === "Enterprise" ? (
                        <>
                          <span
                            style={{
                              fontSize: "14px",
                              marginTop: "36px",
                              marginTop: "44px",
                            }}
                          >
                            <b>{plan.description}</b>
                          </span>
                        </>
                      ) : (
                        <span className={styles.planPrice}>
                          {getPrice(plan?.description).split(".").length > 0
                            ? getPrice(plan?.description).split(".")[0]
                            : ""}
                          <sup style={{ fontSize: "14px" }}>
                            {plan.amount.split(".").length > 0
                              ? "." + plan.amount.split(".")[1]
                              : ""}
                          </sup>
                          <sub style={{ fontSize: "14px" }}>
                            {"/user/" + plan.interval}
                          </sub>
                        </span>
                      )}
                      {plan.name === "Enterprise" ? (
                        <Popconfirm
                          title="Get in touch"
                          description=""
                          okText={<Calendly />}
                          cancelText="Via email"
                          //onConfirm={()=>onViaMeeting(plan)}
                          onCancel={() => onViaMail(plan)}
                        >
                          <button
                            style={{
                              width: plan.name === "Enterprise" ? "70%" : "",
                              marginLeft:
                                plan.name === "Enterprise" ? "35px" : "",
                            }}
                            className={`${styles.planButton} heroButton`}
                          >
                            {plan.name === "Enterprise"
                              ? "Get in touch"
                              : "Sign Up"}
                          </button>
                        </Popconfirm>
                      ) : props.page === "subscriptionplan" ? (
                        <button
                          disabled={plan.name === "Enterprise"}
                          style={{
                            width: plan.name === "Enterprise" ? "70%" : "",
                            marginLeft:
                              plan.name === "Enterprise" ? "35px" : "",
                          }}
                          className={`${styles.planButton} heroButton`}
                          onClick={() => onClickSignup(plan?.id)}
                        >
                          {plan.name === "Enterprise"
                            ? "Get in touch"
                            : "Sign Up"}
                        </button>
                      ) : (
                        <div className="flex w-full items-center justify-center">
                        <button
                          style={{
                            width: plan.name === "Enterprise" ? "70%" : "",
                            marginLeft:
                              plan.name === "Enterprise" ? "35px" : "",
                          }}
                          disabled={
                            plan?.id === userInfo?.user?.plan?.id || isLoading
                          }
                          className={`${styles.planButton} heroButton flex justify-center items-center`}
                          onClick={() => handlePlans(plan)}
                        >
                          {plan.name === "Enterprise" ? (
                            "Get in touch"
                          ) : !userInfo ||
                            userInfo?.user?.role?.name !== "Admin" ? (
                            "Signup"
                          ) : plan?.id === userInfo?.user?.plan?.id ? (
                            <b>Current</b>
                          ) : plan?.id === changigPlan ? (
                            <div className="flex items-center justify-center">
                            <img src={LoaderGif} alt="Loading..." className="w-5 h-5" />
                          </div>
                          ) : (
                            "Change"
                          )}
                        </button>
                        </div>
                      )}
                    </div>
                    <div
                      className={`${styles.plan} ${
                        isCurrent(plan) && props.parent !== "home"
                          ? ""
                          : styles.hideCurrent
                      }`}
                      style={{ color: theme == "light" ? "black" : "white" }}
                    >
                      <ul>
                        {features[plan.name].map((feature, key) => (
                          <li
                            key={key}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <span
                              style={{ fontSize: "24px", color: "#C4242C" }}
                            >
                              ✔
                            </span>
                            <span>{feature}</span>
                          </li>
                        ))}
                      </ul>

                      <div
                        style={{
                          marginTop: "auto",
                          position: "absolute",
                          bottom: "24px",
                        }}
                      >
                        <Link
                          to={
                            props.parent !== "home"
                              ? "/see-features"
                              : "/see-all-features"
                          }
                          className={styles.seeFeatures}
                        >
                          <span>See all features</span>
                        </Link>
                      </div>
                    </div>
                  </GlowCard>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default SubscriptionPage;

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  plans: [], // Array to store plans
  plansIsLoading: false, // Loading state for plans
};

const allPlanSlice = createSlice({
  name: "allPlans",
  initialState,
  reducers: {
    updatePlans: (state, action) => {
      // Update the plans array
      state.plans = action.payload;
    },
    updatePlansLoading: (state, action) => {
      // Update the loading state
      state.plansIsLoading = action.payload;
    },
  },
});

// Export actions
export const { updatePlans, updatePlansLoading } = allPlanSlice.actions;

// Export reducer
export default allPlanSlice.reducer;

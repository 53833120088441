import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";
import BackBtn from "../../backBtn/BackBtn";
import { togglePlan } from "../../../Redux/Slices/plans-slice";
import { toggleSignUp } from "../../../Redux/Slices/signup-slice";
import ParticlesBackground from "../../home/ParticalsBackground";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import AnimationLottie from "../../../lottie/animation-lottie";
import signupAnimation from "../../../lottie/signupanimation.json";

const SignUp = () => {
  const { theme } = useSelector((state) => state.theme);
  const { plan } = useSelector((state) => state.plan);
  const plans = useSelector((state) => state.allPlans.plans);
  const dispatch = useDispatch();
  const navigate = useNavigate();
 const [loading, setLoading] = useState(false);
  //const [plans, setPlans] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [formValues, setFormValues] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirmPassword: "",
    domain: "",
    role: "",
    plan: "",
    quantity:"2",
  });
  const [role, setRole] = useState();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [isChecked, setIsChecked] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const handlePlanChange = (e) => {
    dispatch(togglePlan({plan:e}));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });

    if (name === "password") {
      calculatePasswordStrength(value);
    }
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    setPasswordStrength(strength);
  };

  const handleGetRoles = async () => {
    try {
      const result = await fetchApi.get("roles/");
      if (result.status === 200) {
        const data = result.data.results;
        const userRole = data.find((item) => item.name === "Admin");
        setRole(userRole?.id);
      }
    } catch (error) {
      toast.dismiss();
      toast.error("Something went wrong");
      console.error("Error getting roles: ", error);
    }
  };

  useEffect(() => {
    if (role && plan) {
      setFormValues((prevState) => ({
        ...prevState,
        role: role,
        plan: plan,
      }));
    }
  }, [role, plan]);

  const handleSignUp = async (e) => {
    e.preventDefault();

    if (
      !formValues.first_name ||
      !formValues.last_name ||
      !formValues.email ||
      !formValues.password ||
      !formValues.confirmPassword ||
      !formValues.domain ||
      !formValues.quantity
    ) {
      toast.dismiss();
      toast.error("All fields are required!");
      return;
    }

    if (formValues.password.length < 8) {
      toast.dismiss();
      toast.error("Password must be at least 8 characters!");
      return;
    }
    if (formValues.password !== formValues.confirmPassword) {
      toast.dismiss();
      toast.error("Confirm password and password must be same!");
      return;
    }
    if (!isChecked) {
      toast.dismiss();
      toast.error("Please accept the Terms and Conditions to continue!");
      return;
    }
    setLoading(true);
    dispatch(toggleSignUp({ signupInfo: formValues }));
    navigate("/payment");
    setLoading(false);
  };


  useEffect(() => {
    handleGetRoles();
  }, []);
  return (
    <>
      <style>{`
        .login-box {
          background-color:${
            theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"
          };
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .input-field {
          background-color:${theme === "dark" ? "#444" : "#ffffff"};
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .forgot-link, .terms-text {
          color:${theme === "dark" ? "#ccc" : "#000000"};
        }
        .terms-link {
          color:${theme === "dark" ? "#ffffff" : "#ff2f2f"};
        }
        .password-strength-container {
          width: 100%;
          height: 6px;
          background-color: #ddd;
          border-radius: 3px;
          margin-top: 5px;
        }
        .password-strength {
          height: 100%;
          border-radius: 3px;
          transition: width 0.3s ease;
        }
      `}</style>
      <ParticlesBackground id="particaljs" />
      <div className="login-container">
        <BackBtn bgColor={"red"} />
       <div className="md:flex justify-center lg:w-1/2 md:w-2/5 hidden">
          <AnimationLottie animationPath={signupAnimation} width={"70%"} />
        </div>
        <div className="flex justify-center w-full lg:w-1/2 md:w-auto md:mr-7 mr-0 ">
          <div className={`signup-box ${theme === 'dark' ? 'text-[#ffffff] bg-[#1E1E1E]' :'text-[#000000] bg-[#dfdfdf]'}`}>
          <h2 className="main-heading text-center">Create an account</h2>
            <form onSubmit={handleSignUp}>
             <div className="grid sm:grid-cols-2 grid-cols-1  gap-4">
             <div className="input-group">
                <label className="input-label">Your selected plan</label>
                <div className="custom_dropdown">
                  <span
                    className={`${
                      theme === "dark" ? " text-white" : " text-black"
                    } dropdown_button input-field`}
                    onClick={() => {
                      setIsOpen(!isOpen);
                    }}
                  >
                    {plans.find((item) => item.id === plan)?.name || ""}
                    <IoIosArrowDown />
                  </span>
                  {isOpen && (
                    <ul
                      className={`${
                        theme === "dark"
                          ? "bg-darkMode text-white"
                          : "bg-white text-black"
                      } dropdown_menu`}
                    >
                      {plans.map((planItem) => (
                        <li
                          key={planItem.id}
                          value={planItem.id}
                          onClick={() => {
                            handlePlanChange(planItem.id);
                            setIsOpen(!isOpen);
                          }}
                        >
                          {planItem.name}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>

              <div className="input-group">
                <label className="input-label">Users you want to register</label>
                <input
                  type="number"
                  name="quantity"
                  value={formValues.quantity}
                  min={2}
                  onChange={handleChange}
                  placeholder="Enter number of users"
                  className="input-field"
                />
              </div>

              <div className="input-group">
                <label className="input-label">First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={formValues.first_name}
                  onChange={handleChange}
                  placeholder="Enter your first name"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={formValues.last_name}
                  onChange={handleChange}
                  placeholder="Enter last name"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  placeholder="Enter email"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">Choose a domain</label>
                <div className="domain-main">
                  <div
                    className={`http ${
                      theme === "dark" ? "text-white" : "text-black"
                    }`}
                  >
                    https://
                  </div>
                  <input
                    type="text"
                    name="domain"
                    value={formValues.domain}
                    onChange={handleChange}
                    className="input-field domain-input"
                  />
                  <div className="domain">.bugtrack.com</div>
                </div>
              </div>
              <div className="input-group">
                <label className="input-label">Password</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={formValues.password}
                    onChange={handleChange}
                    placeholder="Enter password"
                    className="input-field"
                    style={{ paddingRight: "35px" }}
                  />
                  <div
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </div>
                </div>
                <div className="w-full h-2 mt-2 rounded-full overflow-hidden">
                  <div
                    className={`h-full rounded-full transition-all duration-300 ${
                      passwordStrength < 2
                        ? "bg-red-500"
                        : passwordStrength < 4
                        ? "bg-orange-500"
                        : "bg-green-500"
                    }`}
                    style={{ width: `${(passwordStrength / 5) * 100}%` }}
                  ></div>
                </div>
              </div>
              <div className="input-group">
                <label className="input-label">Confirm password</label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={formValues.confirmPassword}
                    onChange={handleChange}
                    placeholder="Enter confirm password"
                    className="input-field"
                    style={{ paddingRight: "35px" }}
                  />
                </div>
              </div>
             </div>
              <div className="flex gap-2 items-center mb-2">
                <input
                  type="checkbox"
                  onClick={(e) => setIsChecked(e.target.checked)}
                  value={isChecked}
                />
                <p className="terms-text">
                  By clicking Sign Up, you agree to our{" "}
                  <Link to="/terms_of_service" className="terms-link">
                    Terms of Service
                  </Link>{" "}
                  and{" "}
                  <Link to="/privacy_policy" className="terms-link">
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>
             <div className="flex w-full justify-center items-center">
             <button
                className="heroButton w-full xl:w-1/2"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Next"}
              </button>
             </div>
            </form>
          </div>
        </div>
       
      </div>
    </>
  );
};

export default SignUp;
